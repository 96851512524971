.dining {
  &-wrap {
    position: relative;
    overflow: hidden;
  }
  &-flex {
    display: flex;
    justify-content: center;
    margin: 0 -3.2rem;
    margin-top: 2rem;
    flex-wrap: wrap;
    row-gap: 2rem;
  }
  &-left {
    width: max-content;
    padding: 0 3.2rem;
    border-right: 0.1rem solid #4b5563;
    @include max(600px) {
      width: 100%;
      border-right: none;
      display: flex;
      justify-content: center;
    }
  }
  &-right {
    width: max-content;
    padding: 0 3.2rem;
    @include max(600px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &-top {
    width: calc((8.5 / 12) * 100%);
    margin: 0 auto;
    @include max(1024px) {
      width: 100%;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    gap: 1rem;

    .icon {
      width: 2.4rem;
      height: 2.4rem;
      flex-shrink: 0;
      i {
        @include fz-20;
        color: #1f2937;
      }
    }
    .desc {
      display: flex;
      flex-direction: column;

      .link {
        font-weight: 600;
        transition: 0.3s ease-in-out;
        @include hover {
          color: $color-pri;
        }
      }
    }
  }
  &-btn {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  &-slide {
    margin-top: 4rem;
    .swiper {
      &-wrapper {
        transition-timing-function: linear !important;
      }
      &-slide {
        width: 29%;
        @include max(768px) {
          width: 35%;
        }
        @include max(550px) {
          width: 50%;
        }
      }
    }
  }
  &-img {
    overflow: hidden;
    border-radius: 1.2rem;
    padding-top: 100%;
    position: relative;
    img {
      @include imgCover;
    }
  }
}

.sinfo {
  &-wrap {
    padding-top: 8rem;
    overflow: hidden;
    @include max(992px) {
      padding-top: 6rem;
    }
  }
  &-ctn {
    margin-top: 4rem;
  }
  &-top {
    &-inner {
      @include max(768px) {
        br {
          display: none;
        }
      }
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    width: 25%;
    @include max(992px) {
      width: 50%;
    }
    @include max(768px) {
      width: 100%;
    }
    @include min(992px) {
      &:nth-child(2) {
        transition-delay: 0.4s !important;
      }
      &:nth-child(3) {
        transition-delay: 0.6s !important;
      }
      &:nth-child(4) {
        transition-delay: 0.8s !important;
      }
    }
  }
  &-box {
    position: relative;
    overflow: hidden;
  }
  &-bg {
    position: relative;
    padding-top: calc((712 / 432) * 100%);
    overflow: hidden;
    display: flex;
    @include max(992px) {
      padding-top: 100%;
    }

    img {
      @include imgCover;
    }
  }
  &-desc {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10rem 5rem;
    color: #fff;
    cursor: pointer;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 12, 6, 0.9) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
    @include hover {
      // .title-1 {
      //   opacity: 0;
      //   display: none;
      // }
      // .desc {
      //   -webkit-animation: slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      //     both;
      //   animation: slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      //   opacity: 1;
      //   display: block;
      // }
      .desc {
        transform: translateY(0);

        .txt {
          opacity: 1;
        }
        @include max(1300px) {
          .title-2 {
            padding-bottom: 0;
          }
        }
      }
    }
    @include max(1300px) {
      padding: 5rem 3rem;
    }

    @include max(992px) {
      padding: 1.5rem;
    }

    .title {
      text-align: center;
      @include fz-36;
      font-weight: 600;
      transition: 0.3s ease-in-out;

      &-1 {
        display: none;
      }
    }
    .title-2 {
      text-align: center;
      transition: 0.3s ease-in-out;
      @include max(1300px) {
        padding-bottom: 5rem;
        display: block;
      }
    }
    .desc {
      transition: 0.3s ease-in-out;
      // opacity: 0;
      // display: none;
      transform: translateY(100%);
      @include max(1300px) {
        transform: translateY(calc(100% - 5rem));
      }

    }
    .txt {
      text-align: center;
      opacity: 0;
      transition: 0.3s ease-in-out;
    }
  }
}

.sform {
  &-wrap {
    position: relative;
    padding: 8rem 0;
    overflow: hidden;
    @include max(992px) {
      padding: 4rem 0;
    }
  }
  &-form {
    background-color: #fff;
    border-radius: 2rem;
    box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
      0px 10px 15px -3px rgba(16, 24, 40, 0.1);
    padding: 4rem;
    width: calc((8.5 / 12) * 100%);
    margin: 0 auto;
    @include max(992px) {
      width: 100%;
    }
    @include max(768px) {
      padding: 2rem;
    }
    @include max(550px) {
      padding: 1.5rem;
      .ip-control {
        width: 100% !important;
      }
    }
  }
  &-ctn {
    margin-top: 3rem;
  }
  .ip-control {
    .dateTime {
      // border: 0.1rem solid #7e551b;
      border-radius: 0.8rem;
      padding: 0;

      .dateTimeItem {
        width: 100%;
      }

      input {
        // border: none;
      }
    }
    input,
    textarea,
    .select2-container .select2-selection--single {
      border-color: #7e551b;
    }
  }
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.well {
  &-wrap {
    position: relative;
    overflow: hidden;
  }
  &-inner {
  }
  &-list {
    display: flex;
    row-gap: 4rem;
    flex-direction: column;
    @include max(992px) {
      row-gap: 3rem;
    }
  }
  &-flex {
    display: flex;
    border-radius: 2rem;
    overflow: hidden;
    background-color: #fff;
    @include max(768px) {
      flex-direction: column !important;
    }

    &:nth-child(odd) {
      .well-tag {
        left: 1.5rem;
      }
    }

    &:nth-child(even) { 
      flex-direction: row-reverse;
      .well-tag {
        right: 1.5rem;
      }
    }
  }
  &-left {
    width: 50%;
    @include max(768px) {
      width: 100%;
    }
  }
  &-right {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 3rem 7rem;
    @include max(1200px) {
      padding: 2rem;
    }
  }
  &-img {
    padding-top: calc((460 / 591) * 100%);
    position: relative;
    overflow: hidden;
    height: 100%;
    img {
      @include imgCover;
    }
  }
  &-tag {
    position: absolute;
    border-radius: 8px;
    background: #fefefe;
    @include mid-flex;
    line-height: 1;
    z-index: 5;
    top: 1.5rem;
    @include fz-16;
    color: $color-pri;
    padding: 0.5rem 1rem;
  }
  &-ctn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .sub {
      margin-bottom: 0.4rem;
    }

    .txt {
      margin-top: 2.4rem;
    }

    .btn {
      margin-top: 2.4rem;
    }
  }
  &-btn {
    margin-top: 6rem;
    display: flex;
    justify-content: center;
    @include max(992px) {
      margin-top: 3rem;
    }
  }
}
