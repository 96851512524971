
.faq {
    &-wrap {
        padding-bottom: 10rem;
        @include max(992px) {
            padding-bottom: 6rem;
        }
    }
    .breadcrumb {
        padding: 1.6rem 0;
    }
    &-ctn {
        margin-top:4rem;
        display: flex;
        flex-direction:column;
        gap: 2rem;
        background: #F8F4F0;
        padding: 6rem 10rem;
        box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.10), 0px 4px 6px -1px rgba(16, 24, 40, 0.10);
        border-radius: 2rem;
        @include max(992px) {
            padding: 4rem;
        }
        @include max(768px) {
            padding: 3rem ;
        }
        @include max(550px){
            padding: 1.5rem;
        }

    }
    &-item {
        padding:1rem;
        transition: 0.3s ease-in-out;
        &:not(:last-child) {
            border-bottom: 0.1rem solid #D1D5DB;
        }
    
        @include max(768px) {
            padding: 1rem;
        }

        &.active {
            .faq-item-top {
                .txt {
                    color: $color-pri;
                }
                .icon {
                    i {
                        color: $color-pri;
                    }
                }
     
            }

            @include max(768px) {
                padding: 3rem 1.5rem;
                padding-top: 2rem;
            }
            @include max(550px) {
                padding: 1.5rem;
            }
        }
        &-top {
            padding: 1rem;
            margin: 0 -1rem;
            display: flex;
            justify-content:space-between;
            gap: 2rem;
            align-items: center;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            position: relative;


            @include hover {
              
                .txt {
                    color: $color-pri;
                }
                .icon {
                    i {
                        color: $color-pri;
                    }
                }
            }

            .txt {
                font-weight: 600;
                @include fz-24;
                color: #111827;
                transition: 0.3s ease-in-out;
            }

            .icon {
                display: flex;
                i {
                transition: 0.3s ease-in-out;

                }
      
                &-minus {
                    display: none;
                }
                &-plus {
                    display:block;
                }
            }

            &.active {
                .icon {
                    &-minus {
                        display: block;
                    }
                    &-plus {
                        display:none;
                    }
                }
            }
        }
        &-ctn {
            display: none;
            margin-top: 0.5rem;
            color: #4B5563;
            margin-bottom: 2rem;
            ul {
                padding-left: 2rem;
                list-style: disc;

                li {

                }
            }
        }
    }
}

.slink {
   overflow: auto;
   &::-webkit-scrollbar {
    display: none;
   }

    &-inner {
        display:flex;
        border-radius: 100rem;
        background-color: #F9FAFB;
        width: max-content;
        margin: 0 auto;
        flex-wrap: wrap;
        row-gap: 1rem;
    }

    &-item {
        height: 6rem;
        border-radius: 100rem;
        @include mid-flex;
        padding: 2rem 8rem;
        font-weight: 600;
        color: #6B7280;
        transition:0.3s ease-in-out;
        @include max(1200px) {
            padding: 1.5rem 5rem;
            height: 5rem;
        }

        @include max(992px) {
            padding: 1rem 4rem;
        }

        @include hover {
            background:#F3F4F6;
        }

        &.active {
            background: #EEC387;
            color: #111827;
        }

    }
}