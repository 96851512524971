.footer {
  background: #fff;
  padding: 6rem 0;

  @include max(768px) {
    padding-top: 4rem;
  }

  &-wrap {
    position: relative;
  }
  &-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    padding-bottom: 4rem;
    @include max(768px) {
      gap: 3rem;
    }
    .header-logo {
      position: relative;
      inset: 0;
      transform: none;
      width: 9.2rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .header-srch {
      width: 32rem;
      max-width: 100%;
    }
  }
  .menu-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    gap: 2rem;
    row-gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;

    .menu-item {
      padding: 0 1rem;
      .menu-link {
        width: max-content;
        display: block;
        transition: 0.3s ease-in-out;
        color: $color-pri;
        padding: 0.2rem 0;
        font-weight: 600;
        position: relative;

        &:before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 0.2rem;
          width: 0;
          transition: 0.3s ease-in-out;
          background-color:$color-pri;

        }

        @include hover {
         &:before {
          width: 100%;
         }
        }
      }
    }
  }
  &-flex {
    display: flex;
    margin: 0 -1.6rem;
    flex-wrap: wrap;
    row-gap: 2rem;

  }
  &-col {
    padding: 0 1.6rem;
    width: 50%;
    @include max(900px) {
      width: 100%;
    }
  }

  &-social {
    display: flex;
    gap: 1.6rem;
    flex-wrap: wrap;

    &-item {
      width: 3.2rem;
      height: 3.2rem;
      @include mid-flex;

      img {
        width: 100%;
        object-fit: contain;
        transition: 0.3s ease-in-out;
      }
      @include hover {
        img {
          transform: translateY(-0.5rem);
        }
      }
    }
  }

  &-info {
    padding: 4rem 0;
    
    border-top: 0.1rem solid #E5E7EB;
    &-desc {
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;

    }
  }
  &-map{
    margin-top: 2.4rem;

    iframe {
      border-radius: 1.2rem;
      width: 100%;
      border: 0.1rem solid #E5E7EB !important;
    }
  }

  &-bot {
    padding: 4rem 0;
    padding-bottom: 0;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      background-color: #808080;
      height: 0.1rem;
    }

    &-flex {
      display: flex;
      gap: 2rem;
      row-gap: 1rem;
      justify-content: center;
      flex-wrap: wrap;
      @include max(992px) {
        flex-direction: column;
        align-items: center;
      }
    }
    &-txt {
      display: flex;
      align-items: center;
      color: #fff;
      @include fz-16;
      text-align: center;
    }
  }
  &-sign {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  

    .txt {
      color: $color-text;
      @include fz-16;
    }

    &-link {
      display: inline-flex;
    }

    img {
      height: 1.2rem;
      object-fit: contain;
      width: auto; 
    }
  }
}
