@import url("../assets/font/Galano/stylesheet.css");
@import url("../assets/font/Garamond/stylesheet.css");
@import url("../assets/font/SVN-Futura/stylesheet.css");







* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;

    @media screen and (min-width: 2000px) { 
        font-size: 100%;
    }
}

body {
    // font-family: 'Galano Grotesque','san-serif'; 
    // font-family: 'SVN-Futura','san-serif'; 
    font-family: 'SVN-Futura Book', 'san-serif';
    font-size: 16px;
    line-height: 1.55;
    font-weight: 400;
    color: #4b5563;
    overflow: hidden auto;
    background: #FEFEFE;

    @media screen and (min-width: 2000px) {
        font-size: 20px;
    }
}
body > * {
    font-size: 18px;

}
input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'SVN-Futura','san-serif'; 
}

a {
    text-decoration: none;
    color: inherit;
}

button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;

}

button {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}





.main,
.site-main {
    min-height: 68vh;
    // padding-top: var(--size-hd);
}

.container {
    width: 100%;
    max-width: 123rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;

//    @media only screen and (max-width:1200px) {
//     padding: 0 1.5rem;
//    }
}



.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

iframe {
    vertical-align: middle;
}


img {
    max-width: 100%;
    height: auto;
}

.load-hidden {
    display: none; 
}