.banner {
    &-wrap {
        position: relative;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);

    }
    &-bg {
        position: absolute;
        inset: 0;
        z-index: -1;
        img,video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-inner {
        min-height: calc((800/1728) * 100vw);
        padding-top: var(--size-hd);
        @include mid-flex;
        flex-direction: column;
        gap: 2rem;
    }
    &-ctn {
        margin-top: auto;
        color: #fff;
        padding-top: 2rem;
        @include min(1200px) {
            width: calc((8/12) * 100%);
            margin: 0 auto;
            margin-top: auto;

        }
    }
    &-next {
        display:flex;
        flex-direction:column;
        gap: 0.5rem;
        align-items: center;
        margin-top: auto;
        margin-bottom: 5rem;
        @include max(992px) {
            margin-bottom: 1rem;
        }

        .txt {
            color: #E5E7EB;
            transition:0.3s ease-in-out;
        }
   
        i {
            color: #E5E7EB;
            @include fz-22;
        }

        @include hover {
            i {
                animation: floatingArrow 1.2s ease 0.2s forwards infinite;
            }
        }
    }
}

@keyframes floatingArrow {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(0.5rem);
    }
    100% {
        transform: translateY(0);
    }
}