.gall {
    &-wrap {
        position: relative;
        overflow: hidden;
    }
  &-top {
    .slink {
      margin-top: 5rem;
    }
  }
  &-main {
    margin-top: 3rem;
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 cột với chiều rộng bằng nhau */
    grid-auto-rows: minmax(auto, 28rem); /* Chiều cao hàng tự động */
    gap: 3rem; /* Khoảng cách giữa các ô */
    @include max(1200px) {
      gap: 1rem;
      grid-auto-rows: minmax(auto, 18rem);
    }
    @include max(550px) {
      grid-template-columns: repeat(
        3,
        1fr
      ); /* 4 cột với chiều rộng bằng nhau */
    }
    @include max(440px) {
      grid-template-columns: repeat(
        2,
        1fr
      ); /* 4 cột với chiều rộng bằng nhau */
    }
 
  }
  &-tab {
    &:not(:first-child) {
      display: none;
    }
  }
  &-item {
    display: flex;
    border-radius: 1.2rem;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.4s ease-in-out;
    }
    @include hover {
      img {
        transform: scale(1.05);
      }
    }

    &:nth-child(1) {
    }

    &:nth-child(2) {
      grid-column: span 2;
      grid-row: span 2;
    }
    &:nth-child(4) {
      grid-row: span 2;
    }
    &:nth-child(7) {
      grid-column: span 2;
      grid-row: span 1;
    }
    &:nth-child(9) {
      grid-column: span 2;
      grid-row: span 2;
    }
    &:nth-child(12) {
      grid-column: span 1;
      grid-row: span 2;
    }
    &:nth-child(14) {
      grid-column: span 2;
      grid-row: span 1;
    }
    &:nth-child(19) {
      grid-column: span 2;
      grid-row: span 1;
    }
    &:nth-child(20) {
      grid-column: span 2;
      grid-row: span 1;
    }
    @include max(550px) {
      &:nth-child(12) {
        grid-row: span 1;
      }
      &:nth-child(20) {
        grid-column: span 1;
      }
    }
    @include max(440px) {
      &:nth-child(2) {
        grid-column: span 1;
        grid-row: span 1;
      }

      &:nth-child(3) {
        grid-column: span 2;
        grid-row: span 2;
      }
      &:nth-child(8) {
        grid-column: span 2;
        grid-row: span 1;
      }
      &:nth-child(20) {
        grid-column: span 2;
        grid-row: span 1;
      }
    }
  }
}
