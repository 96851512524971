//general css
.no-scroll {
  overflow-y: hidden !important;
}
.event-none {
  pointer-events: none;
}

.t-end {
  text-align: end;
}
.o-hidden {
  overflow: hidden;
}
.c-pri {
  color: $color-pri;
}
.c-sec {
  color: $color-second;
}
.c-four {
  color: $color-four;
}
.c-five {
  color: $color-five;
}
.c-text {
  color: $color-text;
}
.c-white {
  color: $color-white;
}
.c-black {
  color: $color-black;
}
.c-grey {
  color: $color-grey;
}
.c-red {
  color: #e52346;
}
.c-blue {
  color: #1454d3;
}
.c-green {
  color: #0bae62;
}
.fw-4 {
  font-weight: 400;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.j-beweent {
  justify-content: space-between;
}
.flex-end {
  justify-content: flex-end;
}
.flex-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.d-none {
  display: none;
}
.c-gap-1 {
  column-gap: 1rem;
}
.btnc {
  cursor: pointer;
}
.btn.trans {
  border: 0.1rem solid $color-pri;
}

.mt-16 {
  margin-top: 1.6rem;
}
.mt-24 {
  margin-top: 2.4rem;
}
.mt-50 {
  margin-top: 5rem;

  @include max(768px) {
    margin-top: 3rem;
  }
}
.mt-0 {
  margin-top: 0 !important;
}
.m-auto {
  margin: 0 auto;
}
.mt-10 {
  margin-top: 1rem;
}
.mt-12 {
  margin-top: 1.2rem;
}
.mt-15 {
  margin-top: 1.5rem;
}
.mt-20 {
  margin-top: 2rem;
}
.mt-30 {
  margin-top: 3rem;
}
.mt-32 {
  margin-top: 3.2rem;
}
.mt-40 {
  margin-top: 4rem;

  @include max(768px) {
    margin-top: 2rem;
  }
}
.mt-60 {
  margin-top: 6rem;
  @include max(768px) {
    margin-top: 3rem;
  }
}
.mt-80 {
  margin-top: 8rem;
  @include max(1024px) {
    margin-top: 4rem;
  }
}
.mt-90 {
  margin-top: 9rem;
  @include max(1024px) {
    margin-top: 5rem;
  }
}
.mt-100 {
  margin-top: 10rem;
  @include max(1024px) {
    margin-top: 6rem;
  }
}
.mb-15 {
  margin-bottom: 1.5rem;
}
.mb-50 {
  margin-bottom: 5rem;
}
.mb-60 {
  margin-bottom: 6rem;

  @include max(768px) {
    margin-bottom: 3rem;
  }
}
.mb-100 {
  margin-bottom: 10rem;
  @include max(768px) {
    margin-bottom: 5rem;
  }
}
.pt-30 {
  padding-top: 3rem;
}
.pt-10 {
  padding-top: 1rem;
}
.pb-24 {
  padding-bottom: 2.4rem !important;
}
.pb-200 {
  padding-bottom: 20rem;
}
.pb-250 {
  padding-bottom: 25rem;

  @include max(768px) {
    padding-bottom: 20rem;
  }
}
.sec-py {
  padding: 10rem 0;

  @include max(1300px) {
    padding: 8rem 0;
  }

  @include max(1024px) {
    padding: 6rem 0;
  }

  @include max(768px) {
    padding: 4rem 0;
  }
}

a.under {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0.1rem;
    width: 0;
    transition: 0.3s ease-in-out;
    background-color: black;
  }
  @include hover {
    color: black;
    &:before {
      width: 100%;
    }
  }
}

.t36 {
  font-size: 3.6rem;

  @include max(1200px) {
    font-size: 2.8rem;
  }
}
.t52 {
  font-size: 5.2rem;
  @include max(1200px) {
    font-size: 3.5rem;
  }
}
.t64 {
  font-size: 6.4rem;

  @include max(1200px) {
    font-size: 4.8rem;
  }
}

.none-events {
  pointer-events: none;
}

.hv-item {
  position: relative;
  overflow: hidden;
}
@media (hover: hover) {
  .hv-item:hover::before {
    -webkit-animation: shine 1.25s;
    animation: shine 1.25s;
  }
}
.hv-item::before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.ip-control {
  width: 100%;
  position: relative;

  label {
    font-weight: 700;
    color: $color-text;
    @include fz-16;
  }

  .ip-feild {
    display: flex;
  }

  input {
    height: 4.8rem;
    width: 100%;
    background: #fff;
    box-shadow: none;
    outline: none;
    border: 0.1rem solid #cbd5e1;
    padding: 0 1rem;
    color: $color-black;
    font-weight: 500;
    @include fz-18;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &::placeholder {
      color: rgba(27, 30, 30, 0.7);
    }

    &:focus {
      border: 0.1rem solid $color-black;
    }
  }

  textarea {
    min-height: 9rem;
    width: 100%;
    background: #fff;
    box-shadow: none;
    outline: none;
    border: 0.1rem solid #cbd5e1;
    padding: 1rem;
    color: $color-black;
    font-weight: 500;
    @include fz-18;
    max-width: 100%;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &::placeholder {
      color: rgba(27, 30, 30, 0.7);
    }

    &:focus {
      border: 0.1rem solid $color-black;
    }
  }

  &.x2 {
    width: 50%;
  }
  &.x3 {
    width: calc(100% / 3);
  }
  &.srch {
    input {
      padding-left: 3rem;
    }
    .icon {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      @include mid-flex;
    }
  }

  .select2-container {
    width: 100% !important;
    height: 4.8rem;
    border: none;
  }
  .select2-selection--single {
    padding: 0 0.2rem;
  }
  .select2-container .select2-selection--single,
  .select2-selection__rendered {
    height: 100%;
    display: flex !important;
    align-items: center;
    background: #fff;
    width: 100%;
    border-radius: 0.8rem !important;
  }

  .select2-selection__arrow {
    height: 100% !important;
    right: 10px !important;
  }
}
.select2-search.select2-search--dropdown {
  display: none;
}
.header-nav .menu-item > .menu-link,
.header-nav .menu-item > a,
.header-more .txt,
.header-nav .menu-nav > .menu-list > .menu-item > .menu-link i {
  @include max(1300px) {
    @include fz-16;
  }
}
.header-nav .menu-item > .menu-list {
  min-width: 20rem;
}

.form-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.6rem;

  .ip-control {
    padding: 0 1.6rem;
    margin-bottom: 1.6rem;
  }
  @include max(1200px) {
    margin: 0 -0.6rem;

    .ip-control {
      padding: 0 0.6rem;
    }
  }
}

.f-title {
  font-family: "Garamond Premiere Pro", sans-serif;
}

.title-72 {
  @include fz-72;
  color: #fff;
  line-height: 1.2;
  font-family: "Garamond Premiere Pro", sans-serif;
  font-weight: 600;
}
.title-52 {
  @include fz-52;
  font-weight: 600;
  font-family: "Garamond Premiere Pro", sans-serif;
  color: $color-text;
  line-height: 1.2;

  &.c-pri {
    color: $color-pri;
  }
  &.c-white {
    color: #fefefe;
  }
}
.title-40 {
  @include fz-40;
  font-weight: 500;
  font-family: "Garamond Premiere Pro", sans-serif;
  color: $color-pri;
  line-height: 1.2;

  &.c-white {
    color: #fff;
  }
}
.title-44 {
  color: $color-txt;
  font-weight: 700;
  @include fz-44;
  line-height: 1.2;
}
.title-36 {
  font-weight: 600;
  @include fz-36;
  line-height: 1.2;
  font-family: "Garamond Premiere Pro", sans-serif;
}
.title-32 {
  font-weight: 600;
  @include fz-32;
  line-height: 1.2;
  font-family: "Garamond Premiere Pro", sans-serif;
}
.title-28 {
  font-weight: 600;
  @include fz-28;
  line-height: 1.2;
  font-family: "Garamond Premiere Pro", sans-serif;
}
.title-20 {
  color: $color-txt;
  font-weight: 700;
  @include fz-20;
}

.header-wrap {
  .custom-logo-link img {
    filter: brightness(0) invert(1);
  }
}
.mobile-wr {
  .menu-nav {
    margin-bottom: 2rem;
    justify-content: space-between;
    align-items: center;
    display: none;
    @include max(768px) {
      display: flex;
    }
    .menu-list {
      .menu-link {
        color: #393d48;

        @include hover {
          color: $color-pri;
        }
      }
    }
    .header-more {
      .txt {
        color: #393d48;
      }
      .icon {
        i {
          color: #393d48;
        }
      }
      &-hover {
        z-index: 5;
      }
    }
  }
}

.slink-inner {
  .menu-list-policy {
    display: flex;
    border-radius: 100rem;
    background-color: #f9fafb;
    width: max-content;
    margin: 0 auto;
    flex-wrap: wrap;
    row-gap: 1rem;

    .current_page_item {
      .menu-link {
        background: #eec387 !important;
        color: #111827 !important;
      }
    }

    .menu-link {
      height: 6rem;
      border-radius: 100rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem 8rem;
      font-weight: 600;
      color: #6b7280;
      transition: 0.3s ease-in-out;
      @include max(1200px) {
        padding: 1.5rem 5rem;
        height: 5rem;
      }

      @include max(992px) {
        padding: 1rem 4rem;
      }
      @include hover {
        background: #f3f4f6;
      }
    }
  }
}
.hab-img:nth-child(1) .box,
.hab-img:nth-child(3) .box,
.story-img .box,
.hphoto-item,
.hdin-box,
.smore-img,
.smore-subimg,
.rev-box,
.hfol-box,.sform-form,.dining-img,.about-img,.ab-img,.offer-img,.sphoto-img,.well-flex,.footer-map iframe {
  border-radius: 0;
}

.gall-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  row-gap: 3rem;
  margin: 0 -1.5rem;
  @include max(1200px) {
    margin:  0 -0.8rem;
    row-gap: 1.6rem;
  }
  .gall-item {
    padding: 0 1.5rem;
    width: calc(100% / 3);
    @include max(1200px) {
      padding: 0 0.8rem;
    }
    @include max(650px) {
      width: 50%;
    }

    .inner {
      padding-top: 100%;
      position: relative;
      overflow: hidden;
      width: 100%;

      img {
        @include imgCover;
      }
    }
  }
}
.banner-inner  {
  transition:0s !important;
  opacity: 1 !important;
  transform: unset !important;
}
@include max(650px) {
  .popup-sinfo-img {
    height: 25rem;
  }
}
.dining-img { 
  cursor:pointer; 
}
.offer-box {
  border-radius: 0 !important;
}
.accom-ctn {
  overflow: hidden
}
.accom-top {
  width: 100%;
    overflow-x: auto;
}
.accom-top::-webkit-scrollbar {
  display: none;
}
@include max(768px) {
  .offer-de-desc {
    padding: 1.5rem;
    margin-bottom: 0;
  }
}
.banner-next .txt {
  display: none;
}
.room-form {
  .ip-control .dateTimeItem {
    .wpcf7-form-control-wrap {
      position: static;
    }
  }
}
.pum-container.pum-responsive img {
  width: 100%;
  object-fit: contain;
  margin: 0 !important;
  border-radius: 0.8rem !important;
}
.pum-theme-1022 .pum-container, .pum-theme-lightbox .pum-container {
  padding: 0 !important;
}
.book-form {
  .ip-control input {
    color: #444;
    font-weight: 600;
  }
  .book-top-txt {
    color: #444;
  }
}
.header-srch input {
  @include fz-16;
}
.pum-theme-1022, .pum-theme-lightbox,
.pum.pum-overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
   .pum-container {
    box-shadow: none !important;
  } 
}
html.pum-open.pum-open-overlay.pum-open-scrollable .pum-overlay.pum-active {
  overflow: hidden !important;
}
.pum-container.pum-responsive {
  width: 93% !important;
}