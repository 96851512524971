.option-2 {
  .header {
    background: #fff;

    &.stiky {
    }
  }
  .main,
  .site-main {
    padding-top: var(--size-hd);
  }
  .header-more .txt,
  .header-nav .menu-nav > .menu-list > .menu-item > .menu-link,
  .hamburger-label .hamburger-text,
  .header-more .icon i {
    color: #000;
  }
  .hamburger-label::before,
  .hamburger-label::after,
  .hamburger-label .hamburger-text {
    color: #000;
    background-color: #000;
  }

  .header-wrap {
    .custom-logo-link img {
      filter: unset;
    }
  }
}

.hban {
  .banner-ctn {
    margin-top: unset;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-control {
    width: 4rem;
    height: 4rem;
    border-radius: 100rem;
    border: 0.1rem solid white;
    @include mid-flex;
    margin-top: 1.6rem;
    cursor: pointer;

    i {
      @include fz-18;
    }

    .pause {
      display: none;
    }

    &.active {
      .play {
        display: none;
      }
      .pause {
        display: block;
      }
    }
  }
}

.book {
  transform: translateY(-50%);
  @include max(992px) {
    transform: translateY(0);
    padding: 3rem 0;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.8rem;
    border-radius: 3rem;
    border: 0.1rem solid #cbd5e1;
    padding: 0 1rem;
    color: #262626;
    width: 100%;
    cursor: pointer;

    &-txt {
      color: #262626;
    }

    .icon {
      display: flex;
    }
  }
  &-box {
    padding: 3rem;
    border-radius: 1.2rem;
    background: #f8f4f0;
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    @include max(992px) {
      padding: 1.5rem;
    }
  }
  &-mem {
    position: relative;

    &.active {
      .book-mem-ctn {
        transform: translate(-50%, 0);
        opacity: 1;
        visibility: visible;
      }
    }

    &-ctn {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 1rem);
      width: calc(100% + 6rem);
      z-index: 5;
      transition: 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden;
    }
    &-box {
      margin-top: 1rem;
      padding: 1rem;
      border-radius: 12px;
      border: 1px solid #e5e7eb;
      background: #f8f4f0;
      box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.1);
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    &-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-control {
    padding: 0 0.8rem;
  }
  .form-list {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 0 -0.8rem;
    row-gap: 2rem;
    justify-content: flex-end;

    .ip-control {
      flex: 1;
      margin: 0;
      padding: 0 0.8rem;

      @include max(992px) {
        width: 50%;
        flex: unset;
      }
      @include max(600px) {
        width: 100%;
      }

      label {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-bottom: 1rem;
        .icon {
          width: 2.4rem;
          height: 2.4rem;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .txt {
          line-height: 1;
        }
      }

      .select2-container .select2-selection--single,
      .select2-selection__rendered {
        background: none !important;
        border-radius: 3rem !important;
      }
    }
  }
  input {
    background: none !important;
    border-radius: 3rem !important;
  }
}

.hab {
  &-flex {
    display: flex;
    margin: 0 -1.6rem;
    @include max(700px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
  &-left {
    width: calc((7 / 12) * 100%);
    padding: 0 1.6rem;
    padding-bottom: 9rem;

    @include max(700px) {
      width: 100%;
    }
  }
  &-right {
    flex: 1;
    padding: 0 1.6rem;
  }
  &-box {
    display: flex;
    position: relative;
    margin: 0 -1.6rem;
  }
  &-img {
    display: flex;

    .box {
      width: 100%;
    }
    &:nth-child(1) {
      width: calc((4 / 7) * 100%);
      padding: 0 1.6rem;

      .box {
        padding-top: calc((527 / 385) * 100%);
        position: relative;
        overflow: hidden;
        border-radius: 2rem;

        img {
          @include imgCover;
        }
      }
    }
    &:nth-child(2) {
      flex: 1;
      padding: 0 1.6rem;
    }
    &:nth-child(3) {
      position: absolute;
      width: calc((5 / 7) * 100%);
      bottom: -9rem;
      right: 0;

      .box {
        position: relative;
        overflow: hidden;
        border-radius: 2rem;
        border: 0.8rem solid white;
        padding-top: calc((345 / 483) * 100%);

        img {
          @include imgCover;
        }
      }
    }
  }
  &-desc {
    padding-left: 6rem;
    position: relative;
    margin-top: 2.4rem;

    @include max(992px) {
      padding-left: 0;
      margin-top: 1rem;
      &::before {
        display: none;
      }
    }

    &::before {
      position: absolute;
      top: 1rem;
      height: 0.1rem;
      width: 5rem;
      content: "";
      left: 0;
      background: $color-pri;
    }
  }
  &-link {
    color: $color-pri;
    position: relative;
    text-transform: uppercase;
    display: block;
    width: max-content;
    margin-top: 2rem;
    &::after {
      position: absolute;
      width: 5rem;
      height: 0.1rem;
      content: "";
      background: $color-pri;
      left: calc(100% + 1rem);
      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0.1rem;
      width: 0;
      transition: 0.3s ease-in-out;
      background: $color-pri;
    }
    @include hover {
      &::before {
        width: 100%;
      }
    }
  }
}

.story {
  &-wrap {
    position: relative;
    background: #f8f4f0;
    &::before {
      position: absolute;
      content: "";
      height: 6rem;
      width: 0.1rem;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      background: $color-pri;
      @include max(992px) {
        height: 4rem;
      }
    }
  }
  &-inner {
    @include min(1200px) {
      width: calc((10 / 12) * 100%);
      margin: 0 auto;
    }
  }
  &-flex {
    display: flex;
    margin: 0 -1.6rem;
    position: relative;
    padding-bottom: 35.5%;
    @include max(768px) {
      flex-direction: column;
      padding-bottom: 0;
      row-gap: 2rem;
    }
  }
  &-left {
    width: calc((4 / 10) * 100%);
    padding: 0 1.6rem;
    @include max(768px) {
      width: 100%;
    }
  }
  &-right {
    flex: 1;
    padding: 0 1.6rem;
    margin: 0 -1.6rem;
    display: flex;
    flex-wrap: wrap;
    @include max(768px) {
      margin: 0 -0.8rem;
      row-gap: 1.6rem;
    }
  }
  &-ctn {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include max(768px) {
      align-items: center;

      .txt {
        text-align: center;
      }
    }

    .hab-link {
      &::after {
        right: calc(100% + 1rem);
        left: unset;
      }
    }
  }
  &-img {
    padding: 0 1.6rem;
    @include max(768px) {
      padding: 0 0.8rem;
    }
    .box {
      position: relative;
      border-radius: 2rem;
      overflow: hidden;
      display: flex;

      img {
        @include imgCover;
      }
    }

    &:nth-child(1) {
      width: 50%;
      .box {
        padding-top: calc((480 / 280) * 100%);
        height: 100%;
      }
    }
    &:nth-child(2) {
      width: 50%;
      transform: translateY(40%);
      @include max(768px) {
        transform: translateY(0);
      }
      .box {
        padding-top: calc((480 / 280) * 100%);
      }
    }
    &:nth-child(3) {
      width: calc((7 / 10) * 100%);
      position: absolute;
      left: 0;
      bottom: 0;
      @include max(768px) {
        position: relative;
        width: 100%;
      }

      .box {
        padding-top: calc((325 / 696) * 100%);
      }
    }
  }
}

.hphoto {
  &-item {
    position: relative;
    overflow: hidden;
    border: 0.8rem solid #fff;
    border-radius: 2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  &-ctn {
    position: absolute;
    // inset: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: flex-end;
    padding: 3.2rem 7rem;
    z-index: 2;
    // background: linear-gradient(
    //     180deg,
    //     rgba(0, 0, 0, 0) 0%,
    //     rgba(0, 0, 0, 0.2) 100%
    //   ),
    //   linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);



    .title {
      color: #fff;
    }
    .txt {
      color: #fff;
      @include lc(3);
      @include max(768px) {
        @include fz-16;
      }
    }
    @include max(992px) {
      padding: 3rem;
    }
    @include max(768px) {
      padding: 2rem;
    }
  }
  .swiper {
    &-slide {
      overflow: visible;
    }
  }
  .bg-paper {
    height: 54%;
    bottom: auto;
    border: 0.8rem solid #fff;

    &::before {
      position: absolute;
      inset: 0;
      content: "";
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
    }
  }
  .accom-item {
    .icon {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  .sphoto-top {
    color: #fff;

    .title-52 {
      color: #fff;
    }
    .txt {
      margin-top: 1rem;
    }
  }
  .sphoto-slide {
    @include max(440px) {
      width: 100%;
    }
  }
  .sphoto-img {
    border: none !important;
    border-radius: 0 !important;

    &::before {
      position: absolute;
      content: '';
      inset: 0;
      z-index: 1;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
    }
    @include max(992px) {
      min-height: 40rem;
    }
  }
  .sphoto-btn {
    @include max(440px) {
      display: none;
    }
  }
}

.hdin {
  overflow: hidden;
  &-wrap {
    background: #f8f4f0;
  }
  &-inner {
    @include min(1200px) {
      width: calc((10 / 12) * 100%);
      margin: 0 auto;
    }
  }
  &-flex {
    display: flex;
    margin: 0 -1.6rem;
    position: relative;
    @include max(992px) {
      margin: 0 -0.8rem;
    }
    @include max(768px) {
      flex-direction: column;
      row-gap: 2rem;
    }
  }
  &-left {
    width: calc((4 / 10) * 100%);
    padding: 0 1.6rem;
    display: flex;
    align-items: center;
    @include max(992px) {
      padding: 0 0.8rem;
    }
    @include max(768px) {
      width: 100%;
    }
  }
  &-right {
    flex: 1;
    padding: 0 1.6rem;
    display: flex;
    flex-wrap: wrap;
    @include max(992px) {
      padding: 0 0.8rem;
    }
    @include max(768px) {
      margin: 0 -0.8rem;
      row-gap: 1.6rem;
    }
  }
  &-ctn {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include max(768px) {
      align-items: center;

      .txt {
        text-align: center;
      }
    }

    .btn {
      margin-top: 2rem;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    width: 100%;
  }
  &-item {
    width: 100%;
  }
  &-box {
    position: relative;
    border-radius: 2rem;
    overflow: hidden;
  }
  &-img {
    position: relative;
    overflow: hidden;
    padding-top: calc((312 / 592) * 100%);
    img {
      @include imgCover;
    }
  }
  &-btn {
    position: absolute;
    inset: 0;
    @include mid-flex;
    background: rgba(0, 0, 0, 0.4);

    .btn {
      border-color: #fff;
      .text {
        color: #fff;
      }
      @include hover {
        background: #fff;
        border-color: $color-pri;
        .text {
          color: $color-pri;
        }
      }
    }
  }
}

.rev {
  &-wrap {
    position: relative;
    overflow: hidden;
    padding: 10rem 0;
    background: rgba(0, 0, 0, 0.4);
    @include max(992px) {
      padding: 5rem 0;
    }
  }
  &-main {
    margin-top: 3.2rem;
    padding: 0 3.2rem;
    overflow: hidden;
    @include max(1200px) {
      padding: 0 1.5rem;
    }
  }
  &-item {
    height: 100%;
  }
  &-box {
    padding: 4.5rem 2.4rem;
    border-radius: 1.2rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1.2rem;
    @include max(1200px) {
      padding: 2rem;
    }
    @include max(900px) {
      padding: 1.5rem;
    }

    .txt {
      @include fz-16;
      @include lc(3);
    }
  }
  &-avt {
    width: 6rem;
    height: 6rem;
    border-radius: 100rem;
    overflow: hidden;
    @include max(1200px) {
      width: 5rem;
      height: 5rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper {
    &-slide {
      width: 20%;
      @include max(1200px) {
        width: 25%;
      }
      @include max(768px) {
        width: calc(100% / 3);
      }
      @include max(550px) {
        width: 50%;
      }
      @include max(400px) {
        width: 75%;
      }
    }
    &-pagination-bullet {
      opacity: 1;
      background-color: #ebcfa8;

      &-active {
        background-color: $color-pri;
      }
    }
  }
}

.hfol {
  overflow: hidden;
  &-main {
    margin-top: 6rem;
    @include max(992px) {
      margin-top: 3rem;
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2.4rem;
    margin: 0 -1.2rem;
    @include max(992px) {
      margin: 0 -0.8rem;
      row-gap: 1.6rem;
    }
  }
  &-item {
    padding: 0 1.2rem;
    width: 20%;
    @include max(992px) {
      padding: 00 0.8rem;
    }
    @include max(850px) {
      width: 25%;
    }
    @include max(500px) {
      width: calc(100% / 3);
    }
    @include max(400px) {
      width: 50%;
    }
    &:last-child {
      .hfol-link {
        opacity: 1;
        transform: scale(1);
        visibility: visible;
      }
    }
  }
  &-box {
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;

    @include hover {
      .hfol-link {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &-link {
    position: absolute;
    inset: 0;
    z-index: 5;
    background: linear-gradient(
      0deg,
      rgba(207, 139, 44, 0.6) 0%,
      rgba(207, 139, 44, 0.6) 100%
    );
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    @include mid-flex;
    transition: 0.3s ease-in-out;
    opacity: 0;
    transform: scale(0.7);
    visibility: hidden;
    border-radius: 0;

    .icon {
      width: 4.5rem;
      height: 4.5rem;
      display: flex;
      i {
        font-size:4.5rem;
        color: #fff;
      }
    }
  }
  &-img {
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    display: flex;

    img {
      @include imgCover;
    }
  }
}
