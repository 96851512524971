// Library
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/select2/select2.min.css");
@import url("../assets/library/gallery/lightgallery.min.css");
@import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/datetime/daterangepicker.css");



// Core
@import "./core/reset";
@import "./core/mona";
@import "./core/variable";
@import "./core/font";
@import "./core/base";
@import "./core/mixin";
@import "./core/animation";




// Component
@import "./components/header";
@import "./components/footer";
@import "./components/burger";
@import "./components/mobile";
@import "./components/smenu";
@import "./components/popup";
@import "./components/btn";
@import "./components/backtotop";
@import "./components/scroll";
@import "./components/ip-control";
@import "./components/datetime";
@import "./components/quantity";















// Layout

@import "./layouts/text";
@import "./layouts/pagi";
@import "./layouts/distance";
@import "./layouts/breadcrumb"; 
@import "./layouts/recheck";
@import "./layouts/banner";
@import "./layouts/side";
@import "./layouts/star";














// Pages
@import "./pages/home";
@import "./pages/about";
@import "./pages/blog";
@import "./pages/offer";
@import "./pages/dining";
@import "./pages/accom";
@import "./pages/gallery";






@import "./pages/contact";
@import "./pages/faq";
@import "./pages/policy";



































@import "./pages/spt";
