.breadcrumb {
  max-width: 100%;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 0.2rem;
  }
  &-inner {
      width: fit-content;
      @include max(600px) {
        border-radius:0;
        border: none;
        padding: 0;
      }
  }
  &-list {
    @include flex;
    flex-wrap: wrap;
    row-gap: 0.5rem
  }
  &-item {
    @include flex;
    align-items: center;
    margin-right: 1rem;
    white-space: wrap;
    position: relative;

    &:before {
      position: absolute;
      content:">";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @include fz-16;
      display: none;
    }
  
    &:not(:first-child) {
      column-gap: 0.5rem;
      padding-left: 2rem;

      &:before {
        display: block;
      }
    }
    &:last-child {
      i,
      .breadcrumb-link {
        font-weight: 500;

      }
    }


    &:nth-child(2) {
      transition-delay: 0.4s !important;
    }
    &:nth-child(3) {
      transition-delay: 0.6s !important;
    }
    &:nth-child(4) {
      transition-delay: 0.8s !important;
    }
    &:nth-child(5) {
      transition-delay: 1s !important;
    }
  }
  &-link {
    @include fz-16;
    line-height: 1;
    color: $color-text;
    font-weight: 500;
    transition: 0.3s ease-in-out;

    @include hover {
      color: $color-pri;
    }

  }
}
.breadcrumb-page {
  margin-top: 1rem;
  @include max(1200px) {
    padding-top: 0;
    margin-top: 2.4rem;
  }
  .breadcrumb-list {
    justify-content: unset;
  }
  .breadcrumb-item {
    &::after {
      color: $color-text;
    }
  }
  .breadcrumb-link {
    color: rgba(30, 30, 30, 0.5);
    
  }
}