.header-nav {
  padding: 0 1.2rem;
  height: 100%;
  // @include max($res-mobile) {
  //   display: none;
  // }
  @include max(768px) {
    display: none;
  }
  .menu {
    height: 100%;
    &-nav {
      height: 100%;
      display: flex;
      align-items: center;

      > .menu-list {
        height: 100%;
        justify-content: center;
        margin: 0 -0.8rem;
        > .menu-item { 
          @include flex;
          align-items: center;
          padding: 0 0.8rem;

          @include hover {
            >.menu-link {
                color: $color-pri;

                i {
                  transform:rotate(-180deg);
                  color: $color-pri;
                }
  
                &::before {
                  width:100%;
                }
            }
          }
    
          > .menu-link {
            @include flex;
            align-items: center;
            position: relative;
            line-height: 1;
            cursor: pointer;
            color: #fff;
            font-weight: 500;
            padding: 1rem 0;

            i {
              transition: 0.4s ease-in-out;
              color: $color-text;
            }

            &::before {
              position: absolute;
              width: 0;
              height: 0.2rem;
              background-color:$color-pri;
              content: "";
              left: 50%;
              transform:translateX(-50%);
              bottom:0.2rem;
              transition: 0.3s ease-in-out;
            }
          }

          &.current_page_item,
          &.current-menu-item {
            > a {
              color: $color-pri;

              &::before {
                width: 100%;
              }
            }
          
          }

          > .menu-list {
            top: 100%;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            background: #F9FAFA;


            >.menu-item {
              >.menu-link {
                color: $color-text;
              }
              > .menu-list { 
                background: #F9FAFA;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;


                .menu-item {
                  @include hover {
                    >.menu-link {
                      color: $color-second;
                      i {
                      color: $color-second;
                      }
                    }
                  
                  }
                }
              }
              @include hover {
                >.menu-link {
                  color: $color-second;
                  i {
                  color: $color-second;
                  }
                }
              
              }
          
            }
          }
        }
      }
    }

    &-list {
      @include flex;
      align-items: center;
    }

    &-item {
      position: relative;
      @include transition(0.2s, ease-in-out);

      &:hover {
        > .menu-list {
          @include show;

          > .menu-item {
            &.current_page_item,
            &.current-menu-item {
              > a {

                color: $color-pri;
              }
            }
          }
        }
      }

      .menu-link,
      a {
        @include transition(0.2s, ease-in-out);

        i {
          @include transition(0.2s, ease-in-out);
        }
      }

      > .menu-link,
      > a {
        display: block;

        > i {
          display: none;
          font-weight: 600;
          margin-left: 0.4rem;
          font-size: 1.6rem;
          color: #fff;
        }
      }

      &.dropdown {
        > a {
          > i {
            display: inline-block;
          }
        }
      }

      > .menu-list {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 25rem;
        max-width: 30rem;
        padding:1.6rem 2.4rem ;
        @include flex;
        flex-direction: column;
        align-items: flex-start;
        background: white;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
          0px 8px 8px -4px rgba(16, 24, 40, 0.03);

        @include transitionRe;
        @include hidden(0, -5px);

        > .menu-item {
          margin-left: 0;
          width: 100%;

   
          &:hover {
            &::after {
              opacity: 1;
            }

            > .menu-link,
            > a {
              color: $color-pri;

              &::after {
                width: 100%;
              }
            }
          }

          > .menu-link,
          > a {
            padding: 0.8rem 0;
            @include flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: max-content;
            min-width: 100%;

            &::before {
              position: absolute;
              width: 0;
              height: 0.2rem;
              background-color:$color-pri;
              content: "";
              left: 50%;
              transform:translateX(-50%);
              bottom:0.2rem;
              transition: 0.3s ease-in-out;
            }

            @include hover {
              color:$color-pri  !important;

              &::before {
                width: 100%;
              }
            }

            .icon {
              width: 2.2rem;
              height: 2.2rem;
              @include mid-flex;
              img {
                width: 100%;
                object-fit: contain;
              }
            }
          }

          > .menu-list {
            left: 100%;
          }
        }
      }
    }
  }
}

.mobile-nav {
  padding-bottom: 1.2rem;
  .menu {
    &-nav {
      > .menu-list {
        > .menu-item {
          &.current_page_item,
          &.current-menu-item {
            > a {
              color: $color-pri;
            }
          }

          &:has(.current-menu-item) {
            > a {
              color: $color-pri;
            }
          }

          > a {
            padding: 1.2rem;
            @include flex;
            justify-content: space-between;
            align-items: center;
            color: #303030;
            @include fz-16;
            font-weight: 600;
            text-transform: uppercase;
            border-bottom: 1px solid $color-border-01;

            &::before {
              display: none;
            }
          }
        }
      }
    }

    &-item {

      &.current_page_item,
      &.current-menu-item {
        > a {
          color: $color-pri;
        }
      }

      >.icon {
        padding: 1.5rem;
        height: 100%;
        cursor: pointer;
        i {
          font-size:1.6rem;
        }
      }
      &.dropdown {
        > a {
          > i {
            display: flex;
          }
        }
      }

      &.active {
        > a {
          > i {
            transform: rotate(180deg);
          }
        }
      }

      a {
        position: relative;
        border-radius: 0.4rem;
        transition:0.3s ease-in-out;


        @include hover {
          background-color: #F3F4F6;
          color: #393D48;

        }

        i {
          width: 2.5rem;
          height: 2.5rem;
          @include mid-flex;
          @include transitionRe;
        }
      }

      > a {
        @include flex;
        align-items: center;
        justify-content: space-between;
        color: $color-text;
        padding: 1rem 0.8rem;
        width: 100%;
      }

      > .menu-list {
        display: none;
        margin-left: 1.6rem;
        position: relative;

   

        > .menu-item {
          &.current_page_item,
          &.current-menu-item {
            > a {
              color: $color-pri;
            }
          }
      
        }
      }
    }
  }

}

