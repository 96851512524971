.blog {
    
}

.dblog {
    &-flex {
        display: flex;
        margin:   0 -1.6rem;
        padding-top: 3.2rem;
        flex-wrap: wrap;
        row-gap: 2rem;
        @include max(992px){
            margin:0 -0.8rem;
        }
    }
    &-left {
        width: calc((8/12) * 100%);
        padding: 0 1.6rem;
        @include max(992px) {
            padding: 0 0.8rem;
        }
        @include max(768px) {
            width: 100%;
        }
    }
    &-right {
        flex: 1;
        padding: 0 1.6rem;
        @include max(992px) {
            padding: 0 0.8rem;
        }

        .offer-item  {
            width: 100% !important;
            padding: 0;
        }
        .offer-box {
            box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.10), 0px 10px 15px -3px rgba(16, 24, 40, 0.10);
            padding: 0;
        }
        .offer-img {
            padding-top: calc((371/384)  * 100%);
        }
        .offer-desc {
            padding: 2rem;
            padding-top: 0;
            @include max(992px) {
                padding: 1rem;
            }
        }
        .offer-name {
            @include fz-28;
        }
    }
    &-fix {
        position: sticky;
        top: var(--size-hd);
    }
    &-ctn {
        padding-bottom: 3.2rem;
        margin-bottom: 3.2rem;
        border-bottom:0.1rem solid #D1D5DB;
        img {
            border-radius:2rem;
            width: 100%;
            object-fit: contain;
            margin: 2rem 0;
        }
    }
    &-box {
        padding:0.5rem 1.5rem ;
        border-left: 0.3rem solid $color-pri;
    }
    &-top {
        .txt {
            color: $color-text;
        }
    }
    &-img {
        position: relative;
        padding-top: calc((716/1216) * 100%);
        overflow: hidden;
        border-radius:2rem;
        margin-top: 3rem;


        img {
            border-radius:2rem;
            @include imgCover;
            transition: 0.4s ease-in-out;
        }
        @include hover {
            img {
                transform: translate(-50%,-50%) scale(1.05);
            }
        }
    }
}

.stag {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    &-item {
        @include fz-16;
        @include mid-flex;
        padding: 0.5rem 1rem;
        color: $color-text;
        transition: 0.3s ease-in-out;
        background-color:#F3F4F6;

        @include hover {
            color: $color-pri;
            background-color: #FAF3EA;
        }
    }
}