// --------------------------------------
.mobile {
  position: fixed;
  z-index: 140;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40rem;
  background-color: white;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
  @include transition(0.4s, linear);
  transform: translateX(-110%);
  overflow: hidden;

  @include max($mobile) {
    width: 70%;
    min-width: initial;
  }
  @include max(440px) {
    width: 100%;
  }



  &.open {
    transform: translateX(0);
  }

  // @include min($res-mobile-min) {
  //   display: none;
  // }

  &-con {
    width: 100%;
    height: 100%;
    @include flex;
    flex-direction: column;
    overflow-x: hidden;
    padding: 4.4rem 2rem;
    padding-top: 0;

    .header-logo {
      height: auto;
      padding: 0;
      justify-content: flex-start;
      position: relative;
      inset: 0;
      transform: none;
      justify-content: center;
    }
    .header-srch{
      margin-bottom: 1rem;
      input {
        width: 100%;
      }
    } 

  }

  &-heading {
    font-size: 1.8rem;
    line-height: 1.5;
    color: $color-pri;
    font-weight: 700;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  &-overlay {
    @include full-fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
    border-top: 1px solid $color-border-01;
    top: 0;


    &.open {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }

    // @include min($res-mobile-min) {
    //   display: none;
    // }
  }

  &-wr {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    @include flex;
    flex-direction: column;

    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0.1rem;
      width: calc(100% + 6rem);
      background:#D9D9D9;
    }
    .header-search {
      display: block;
      padding: 1.2rem;
      padding-bottom: 0;
    }
  }

  &-social {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    padding-top: 1.2rem;
    border-top: 0.1rem solid #D9D9D9;
  }

  &-contact {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &-list {
    margin-bottom: 2rem;
  }


  &-title {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1.6rem;
  }


  &-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 4.4rem;
    height: 4.4rem;
    padding: 1.5rem;
    @include mid-flex;
    cursor: pointer;
    z-index: 5;

    i {
      color: #000000;
      font-weight: 300;
      @include fz-22;
    }
  }



}
