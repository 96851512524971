.popup {
  position: fixed;
  @include full;
  z-index: 999;
  visibility: hidden;

  &.open {
    visibility: visible;

    .popup-overlay {
      opacity: 1;
      visibility: visible;
    }

    .popup-main {
      opacity: 1;
      visibility: visible;
    }
  }

  &-overlay {
    position: absolute;
    background-color: rgba($color: $color-black, $alpha: 0.8);
    @include full;
    z-index: 1;
    @include transitionRe;
    opacity: 0;
    visibility: hidden;
  }

  &-main {
    @include mid;
    z-index: 2;
    width: 90rem;
    max-height: 90vh;
    max-width: 95%;
    @include transitionRe;
    opacity: 0;
    visibility: hidden;
    position: relative;
    background-color: $color-white;
    padding: 2.4rem;

  }

  &-over {
    overflow-y: auto;
    min-height: 40vh;
    max-height: 80vh;
    padding-right: 1rem;
    margin-right: -1rem;
  }

  &-wrapper {
    width: 100%;
    min-height: 40vh;
    position: relative;
  }

  &-title {
    @include fz-24;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.6rem;
  }

  &-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    width: 3rem;
    height: 3rem;
    @include mid-flex;
    z-index: 100;

    &:hover {
      .icon {
        transform: rotate(180deg);
      }
    }

    .icon {
      color: $color-black;
      font-weight: 300;
      font-size: 2.4rem;
      @include transition(0.6s, ease-in-out);

      @include max(800px) {
        @include fz-16;
      }
    }
  }

  &-search {
    width: 100%;
    padding: 1rem;
    padding-top: 5rem;

    &-box {
      width: 40rem;

      .popup-over {
        max-height: 90vh;
      }
    }
    &-logo {
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 50%;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
    &-icon {
      .icon {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
      }
      i {
        font-size: 4rem;
        font-weight: 700;
      }
    }

    &-input {

      &.active {
        &::before {
          display: none;
        }
      }

      input {
        width: 100%;
        transition: 0.4s;
        padding: 1rem 1.6rem;
        border: 0.1rem solid $color-pri;
        height: 4.4rem;
      }

      .btn {
        width: 100%;
        margin-top: 1rem;
        height: 4.4rem;
        justify-content: center;
      }
    }


  }
  &-sinfo {
    .popup {
      &-main {
        width: 80vw;
        padding: 2rem;
        @include max(768px) {
          padding-top: 4rem;
        }
      }
      &-over {
        overflow-x: hidden;
      }
    }
    &-inner {
      display: flex;
      margin: 0 -2rem;
      align-items: center;
      @include max(992px) {
        flex-direction: column;
      }
      @include max(768px) {
        margin: 0;
      }
      ul {
        padding-left: 2rem;
        list-style: disc;
      }
    }
    &-img {
      width: 50%;
      margin: 0 2rem;
      padding-top: calc((280/760) * 100%);
      position: relative;
      overflow: hidden;
      height: max-content;
      display: block;

      @include max(768px) {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-top: calc((350/760) * 100%);

      }

      img {
        @include imgCover;
      }
    }
    &-ctn {
      margin-top: 3rem;
      flex: 1;
      padding: 0 2rem;
      @include max(768px){
        padding: 0;
      }
    }
  }
  &-re {
    .popup {
      &-main {
        width:60rem;
        background: #F8F4F0;
        padding: 4rem 2.4rem;
        @include max(992px) {
          padding: 2rem;
        }
      }
      &-over {
        overflow:hidden auto;
      }
    }
    .room-total {
      margin-top: 1.6rem;
    }
    .ip-control {
      input {
        background:none;
      }
    }
  }
  &-gt {
    .popup {
      &-main {
        padding: 0;
      }
      &-wrapper {
        min-height: unset;
      }
      &-close {
        background: #fff;
        right: 0;
        top: 0;
      }
    }
    &-img {
      display: flex;
      padding-top: calc((1080/1920) * 100%);
      position: relative;
      overflow: hidden;
      img {
        @include imgContain;
        height: auto;
        // width: 100%;
        // height: 100%;
        // object-fit: contain;
      }
    }
    &-desk {
      @include max(768px) {
        display:none;
      }
    }
    &-mb {
      display:none !important;
      padding-top: calc((340/227) * 100%);

      img {
        height: 100%;
      }
      @include max(768px) {
        display:flex !important;
      }
    }
  }
}
.popup-re .dateTimeText.disable {
  opacity: 0 !important;
}
