.accom {
  &-flex {
    display: flex;
    @include max(900px) {
      flex-direction: column-reverse !important;
    }
  }
  &-left {
    width: 50%;
    padding-left: calc((100vw - 123rem) / 2);
    @include max(900px) {
      width: 100%;
    }
  }
  &-right {
    width: 50%;
    @include max(900px) {
      width: 90%;
    }
    @include max(768px) {
      width: 100%;
    }
  }
  &-row {
    position: relative;
    &:nth-child(odd) {
      .accom-left{
        padding-right: 1.5rem;
      }
      .accom-slide {
        border-radius: 0.8rem 0 0 0.8rem;
        margin-left: 3rem;
      }
      @include max(900px) {
        .accom-right {
          margin-left: auto;
        }
      }
      @include max(1200px) {
        .accom-left { 
          padding-right: 0;
        }
        .accom-ctn {
          padding-right: 0.5rem;
        }
        .accom-slide { 
          margin-left:1rem;
        }
      }
    }
    &:nth-child(even) {
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-image: url("../assets/images/paper.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        z-index: -1;
      }
      .accom-flex {
        flex-direction: row-reverse;
      }
      .accom-left {
        padding-left: 1.5rem;
        padding-right: calc((100vw - 123rem) / 2);

        @include max(900px) {
          padding-left: 0;
        }
      }
      .accom-slide {
        border-radius: 0 0.8rem 0.8rem 0;
        margin-right: 3rem;
       

      }
      @include max(1200px) {
        .accom-left {
          padding-left: 0;
          .accom-ctn {
            padding-left:1rem
          }
        }
        .accom-slide { 
          margin-right: 1rem;
        }
      }
    }
  }
  &-ctn {
    padding: 1.5rem;
    @include max(900px) {
      padding: 1.5rem !important;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2rem;
  }
  &-item {
    display: flex;
    gap: 1rem;
    align-items: center;
    line-height: 1;

    .icon {
      width: 2.4rem;
      height: 2.4rem;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &-tab {
    margin-top: 2rem;

    &-ctn {
      &:not(:first-child) {
        display: none;
      }
      ul {
        padding-left: 2rem;
        list-style: disc;
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1.2rem;
      margin: 0 -0.5rem;

      .accom-item {
        width: 50%;
        gap: 1.5rem;
        padding: 0 0.5rem;
        @include max(768px) {
          gap: 1rem;
        }
        @include max(440px) {
          width: 100%;
        }

        .icon {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
  &-top {
    display: flex;
    margin-bottom: 2rem;

    &-inner {
      max-width: 100%;
      overflow-x: auto;
      display: flex;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-item {
      @include fz-20;
      font-weight: 600;
      font-family: "Garamond Premiere Pro", sans-serif;
      color: $color-pri;
      display: flex;
      padding: 0.8rem 2rem;
      position: relative;
      text-align: center;
      white-space: nowrap;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0.2rem;
        background-color: $color-pri;
        z-index: 2;
        transform: translateX(-50%);
        transition: 0.3s ease-in-out;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.2rem;
        background-color: #d1d5db;
        z-index: 1;
      }
      &.active {
        &:before {
          width: 100%;
        }
      }
      @include hover {
        &:before {
          width: 100%;
        }
      }
    }
  }
  &-img {
    position: relative;
    padding-top: calc((667 / 883) * 100%);
    overflow: hidden;

    img {
      @include imgCover;
    }
  }
  &-slide {
    position: relative;
    overflow: hidden;
    @include max(768px) {
      border-radius: 0 !important;
      margin: 0 !important;
    }

    .swiper {
      margin: 0;

      &-slide {
        padding: 0;
        position: relative;
        z-index: 1;
        overflow: hidden;
      }
    }
  }
  &-control {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    width: 100%;
    padding: 0 1.5rem;
  }
  &-btn {
    display: flex;
    flex-direction: row-reverse;
    border-radius: 0.8rem;
    overflow: hidden;
    height: 6rem;
    @include max(992px) {
      height: 4rem;
    }
    &-next,
    &-prev {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0;
      border: none;
      height: 6rem;
      width: 6rem;
      position: relative;
      inset: 0;
      margin: 0;
      @include max(992px) {
        height: 4rem;
        width: 4rem;
      }

      &::before {
        display: none;
      }
    }
  }
  &-pagi {
    .swiper-pagination {
      margin-top: 0;
      background: rgba(255, 255, 255, 0.2);
      height: 6rem;
      padding: 0.5rem 2rem;
      @include mid-flex;
      color: #fff;
      border-radius: 0.8rem;
      overflow: hidden;
      @include max(992px) {
        height: 4rem;
      }
    }
  }
}

.stab {
  &-wrap {
    background: #f8f4f0;
  }

  &-ctn {
  }
  &-tab {
    &-ctn {
      &:not(:first-child) {
        display: none;
      }
      width: calc((10 / 12) * 100%);
      margin: 0 auto;
      @include max(768px) {
        width: 100%;
      }
    }
  }
  &-content {
    h3 {
      font-family: "Garamond Premiere Pro", sans-serif;
      @include fz-20;
      font-weight: 600;
    }
    p {
      color: #6b7280;
    }
  }

  .accom-top {
    justify-content: center;
    margin-bottom: 3rem;

    &-item {
      width: calc(100% / 3);
      justify-content: center;
      @include fz-24;
      padding: 1.2rem;
      min-width: max-content;
    }
  }

  .tab-readmore {
    max-height: 40rem;
    overflow: hidden;
    position: relative;
    transition: height 0.3s ease;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 40%;
      left: 50%;
      transition: 0.3s ease-in-out;
      transform: translateX(-50%);
      background: linear-gradient(
        180deg,
        rgba(248, 244, 240, 0) -51.33%,
        #f8f4f0 100%
      );
    }
  }

  input:checked ~ .tab-readmore {
    max-height: 999rem;

    &::after {
      opacity: 0;
    }
  }
  input:checked ~ .btn-drop-text {
    .btn {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .btn-drop-text {
    border: unset;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    margin: 0 auto;
    margin-top: 1rem;

    .text,
    .icon {
      transition: all 0.3s ease;
    }

    .text:nth-child(2) {
      display: none;
    }
    @include hover {
      i {
        animation: floatingArrow 1.2s ease 0.2s forwards infinite;
      }
      .text,
      .icon {
        color: $color-pri;
      }
    }
  }

  input:checked ~ .btn-drop-text {
    .text:nth-child(1) {
      display: none;
    }
    .text:nth-child(2) {
      display: block;
    }
    i {
      transform: rotate(-180deg) !important;
    }
  }
}

.room {
    overflow: hidden;
  &-flex {
    display: flex;
    margin: 0 -1.6rem;
    flex-wrap: wrap;
  }
  &-left {
    width: calc((4 / 12) * 100%);
    padding: 0 1.6rem;
    @include max(1100px) {
        width: 50%;
    }
    @include max(768px) {
        width: 100%;
        margin-bottom: 2rem;
    }
  }
  &-right {
    flex: 1;
    padding: 0 1.6rem;
  }
  &-box {
    padding: 4rem 2.4rem;
    border-radius: 2rem;
    background: #f8f4f0;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1),
      0px 4px 6px -1px rgba(16, 24, 40, 0.1);
      @include max(1200px) {
        padding:3rem 1.5rem;
      }
  }
  &-form {
    margin-top: 3rem;

    .form-list {
        margin: 0 -0.8rem;
        .ip-control {
            padding: 0 0.8rem;
        }
    }
  }
  &-ex {
    @include fz-16;
    color: $color-grey;
    margin-top: 1.6rem;
    padding-bottom: 3.2rem;
    &-list {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      margin-top: 1.6rem;
    }
    &-flex {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
    }
    &-left {
      display: flex;
    }
    &-right {
      display: flex;
      align-items: center;
      gap: 1rem;

      .txt {
        text-align: right;
      }

      .ip-control {
        width: max-content;
        min-width: 5rem;

        .select2-selection__rendered {
          color: $color-txt;
        }

        .select2-container {
          height: 4rem;
        }
      }
      .ip-control .select2-container .select2-selection--single,
      .ip-control .select2-selection__rendered {
        background: none;
      }
    }
  }
  &-total {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    padding-top: 3.2rem;
    border-top: 0.1rem solid #d9d9d9;
    align-items: center;
    .price {
        color: $color-txt; 
        @include fz-24;
    }
  }
  &-control {
    padding-top: 3.2rem;
  }
  &-ctn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .accom-item {
        .icon {
            width: 2rem;height: 2rem;
        }
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1rem;
  }
  &-row {
    padding-top: 4.8rem;
    @include fz-16;
    @include max(1100px) {
        padding-top:2.4rem;
    }

    ul {
        padding-left:2rem;
        list-style: disc;
    }

    .room-list {
        column-gap: 0;
        margin: 0 -0.8rem;
    }
    .accom-item {
        width: calc(100% / 3);
        padding: 0 0.8rem;
        @include max(992px) {
            width: 50%;
        }
    }
  }
  &-next {
    .txt,i {
        color: $color-txt;
    }
    .banner-next {
        margin: 0;
    }
  }
  &-back {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    &-link {
      color: $color-pri;
      position: relative;
      padding: 0.5rem 0;
      line-height:1.3;
      &:after {
        content: "";
        position: absolute;
        right: calc(100% + 1rem);
        top: 50%;
        transform: translateY(-50%);
        width: 5rem; 
        height: 0.1rem;
        background:$color-pri;
      }
      &::before{
        position: absolute;
        content: "";
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 0.1rem;
        width: 0;
        transition: 0.3s ease-in-out;
        background: #CF8B2C;
      }
      @include hover {
        &::before {
          width: 100%;
        }
      }
    }
  }
}

.sphoto {
    overflow: hidden;
    &-wrap{
        position: relative;
    }
    &-ctn {
        margin-top: 3.2rem;
    }
    &-slide {
        position: relative;
        width: calc((10/12) * 100%);
        margin:0 auto;

        .swiper {
            overflow: visible;
        }

        .swiper-pagination {
            // position: absolute;
            // left: 50%;
            // transform: translateX(-50%);
            // bottom: 0;
        }
    }
    &-img {
        position: relative;
        padding-top: calc((618/1008) * 100%);
        border: 0.5rem solid #fff;
        border-radius:2rem;
        overflow: hidden;
        display: block;

        img {
            @include imgCover;
        }
    }
    &-pagi {
        // position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        // bottom: 0;
        
       
    }
    &-btn {
        @include max(1330px){
            .swiper-button-next, .swiper-button-prev {
                position: absolute;
                inset: unset !important;
                top: 50% !important;
                transform: translateY(-50%) !important;
            }   
            .swiper-button-next {
                right: -5rem !important;
            }
            .swiper-button-prev {
                left: -5rem !important;
            }
        }
        @include max(650px) {
            .swiper-button-next {
                right: -1rem !important;
            }
            .swiper-button-prev {
                left: -1rem !important;
            }
        }
    }
}

.ip-control {
  &-date {
    border-radius: 0.8rem;
    border: 0.1rem solid #d1d5db;
    padding-left: 1.2rem;

    .txt {
      color: #4b5563;
      @include fz-18;
    }

    .dateTime {
      flex: 1;

      input {
        background: none;
        border: none;
        text-align: right;
        padding-right: 3rem;
      }
      .dateTimeText {
        text-align: right;
        padding-right: 3rem;
      }
      .icon {
        width: 1.2rem;
        height: 1.2rem;
        i {
          @include fz-12;
        }
      }
    }
    .select2-container {
      flex: 1;
    }
    .select2-container .select2-selection--single,
    .select2-selection__rendered {
      background: none !important;
      border: none;
    }
    .select2-container--default
      .select2-selection--single
      .select2-selection__rendered {
      justify-content: flex-end;
      padding-right: 3rem;
    }
    .select2-selection__arrow {
      right: 0.7rem !important;
    }
  }
}
