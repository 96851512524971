.header {
  height: var(--size-hd);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 99;
  // border-bottom: 0.1rem solid #CBD5E1;  


  &.sticky {
    height: 7rem;
    background: #fff;

    .header-more .txt,
    .header-nav .menu-nav > .menu-list > .menu-item > .menu-link,
    .hamburger-label .hamburger-text,
    .header-more .icon i{
        color: #000;
    }
    .hamburger-label::before, .hamburger-label::after, .hamburger-label .hamburger-text {
        color: #000;
        background-color: #000;
    }
    .header-wrap {
      .custom-logo-link img {
        filter: unset;
      }
    }
    .header-nav .menu-nav > .menu-list > .menu-item > .menu-link {
      @include hover {
        color: $color-pri;
      }
    }
  }

  &-wrapper {
    position: relative;
    z-index: 98;
    height: inherit;
  }

  &-burger {
    // @include min($res-mobile-min) {
    //   display: none;
    // }
  }

  &-logo {
    height: inherit;
    @include mid-flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);



    &-mobile {
      display:none;
      position: relative;
      @include max(1200px) {
        display: block;

        .custom-logo-link {
          width: 10rem !important;
        }
      }
    }

  
  }
  .custom-logo-link {
    display: block;
    @include transitionRe;
    width: 9.2rem;
    height: 100%;
    
    img {
      display: block;
      max-width: 100%;
      height: auto;
      transition: 0.3s ease-in-out;
      width: 100%;
      object-fit: contain;
      height: 100%
    }
  }
  &-wrap {
    @include flex;
    height: 100%;
    align-items: center;
    @include max(1200px) {
      justify-content: flex-start;
      width: 100%;
      gap: 2rem;

    }
  }
  &-gr {
    @include flex;
    flex: 1;
    gap: 0.8rem;
    height: inherit;
    align-items: center;
    justify-content: flex-end;
    @include max(1200px) {
      flex: unset;
      margin-left: auto;
    }
  }



  &-more {
    position: relative;
    padding: 0 1.5rem;
    border-right: 0.1rem solid #fff;

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
    &-top {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
    }
    .txt {
      color: #fff;
      line-height: 1;
      transition: 0.3s ease;

    }
    .icon {
      display: flex;
      i {
        color: #fff;

        @include fz-16;
        transition: 0.3s ease;
      }
    }
    &-hover {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: max-content;
      max-width: 20rem;
      transition: 0.3s linear;
      opacity: 0;
      visibility: hidden;
    }
    &-inner {
      margin-top: 2rem;
      background-color: #fff;
      padding: 1rem;
      border: 0.1rem solid #E2E8F0;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .menu-link {
        display: block;
        padding: 0.5rem 0;
        position: relative;
        transition: 0.3s ease-in-out;

        &:before {
          position: absolute;
          content: "";
          bottom: 0.5rem;
          left: 50%;
          transform: translateX(-50%);
          height: 0.2rem;
          width: 0;
          transition: 0.3s ease-in-out;
          background-color: $color-pri;
        }
        @include hover {
          color: $color-pri;
          &:before {
            width: 100%;
          }
        }
      }
    }
    @include hover {
      .header-more-hover {
        opacity: 1;
        visibility: visible;
      }
      .header-more-top {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &-srch {
    position: relative;

    &-input {
      position: relative;
    }
    input{
      height: 4rem;
      border: 0.1rem solid #999;
      padding: 0 1rem;
      padding-right: 4rem;
      border-radius: 0.8rem;
      width: 100%;

      &:focus {
        box-shadow:none;
        outline: none;
      }
    }
    button,.btn {
      position: absolute;
      right: 0;
      top: 50%;
      transform:translateY(-50%);
      height: 100%;
      width: 4rem;
      min-width: unset;
      padding: 1rem;

      @include hover {
        background: none;
      }

      .icon {
        margin: 0 !important;
        justify-content: center;
        i {
          color: #999999;
        }
      }
    }
  }

  &-control {
    display: flex;
    column-gap: 1.6rem;
    align-items: center;
    @include max(992px) {
      .btn {
        min-width: 7rem;
        height: 3.8rem;
        padding: 1rem;
        .text {
          @include fz-16;
        }
      }
    }
    @include max(550px) {
      .btn {
        .text {
          @include fz-10;
        }
      }
    }

  }

}



