.contact {
    &-wrap {
        position: relative;
    }
    &-flex {
        display: flex;
        @include max(650px) {
            flex-direction:column;
        }

    }
    &-left {
        width: 50%;
        display: flex;
        align-items: center;
        padding-left: calc((100vw - 123rem) / 2);
        @include max(650px) {
            width: 100%;
            padding: 2rem 0;
        }
    }
    &-right{
        flex: 1;
        display: flex;

        &-inner {
            position: relative;
            height: 100%;
            width: 100%;
        }
        &-bg{
            position: absolute;
            inset: 0;
            z-index: -1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &-ctn {
        display: flex;
        flex-direction: column;
        gap: 4.8rem;
        padding: 0 1.5rem;
    }
    &-info {
        .title {
            @include fz-32;
            font-weight: 600;
            color: $color-text;
            line-height: 1.2;
        }
        &-desc {
            display: flex;
            flex-direction: column;
        }
    }
    &-form {
        padding: 4rem 2.4rem;
        background:#fff;border-radius: 2rem;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
        width: 72%;
        margin: 9rem auto;

        @include max(1200px) {
            width: 85%;
            margin: 5rem auto;
        }



        .form-list {
            margin-top: 3.2rem;

            .ip-control {
                input,
                textarea {
                    border-color: $color-pri;
                }
            }
        }


    }
}