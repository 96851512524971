.back-to-top {
  width: 4rem;
  height: 4rem;
  background: white;
  border-radius: 0.6rem;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 7;
  transform: translateX(3rem);
  opacity: 0;
  transition: 0.5s linear;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 100rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include min(1200px) {
    img {
      -webkit-animation: shake-vertical 15s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      infinite both;
    animation: shake-vertical 15s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
      both;
    }
  }
  @include max(1200px) {
    width: 4rem;
    height: 4rem;
  }
  @include max(768px) {
    right: 1.5rem;
  }
  &.active {
    visibility: visible;
    transform: translateX(0);
    opacity: 1;
  }
  .triangle {
    display: block;
    width: 100%;
    height: 0.5rem;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: $color-third;
    }

    &:nth-child(1) {
      animation: BTT 1.5s linear 0.6s infinite;
    }
    &:nth-child(2) {
      animation: BTT 1.5s linear 0.3s infinite;
    }
    &:nth-child(3) {
      animation: BTT 1.5s linear 0s infinite;
    }
  }



@include hover {
    .icon {
        -webkit-animation: slide-top 1s cubic-bezier(.25,.46,.45,.94) infinite alternate both;
        animation: slide-top 1s cubic-bezier(.25,.46,.45,.94) infinite alternate both;
    }

}
  .icon {
    display: flex;
  }
  .btn-rotate {
    width: 100%;
    height: 100%;
    .rounded-text {
      fill: white;
    }
  }
}
@include keyframes(BTT) {
  0% {
      opacity: 0;
  }
  33% {
      opacity: 1;
  }
  66% {
      opacity: 0;
  }
  100% {
      opacity: 0;
  }
}
