.quantity {
    display: flex;
    gap: 2.4rem;
    flex-direction: column;
  

    .count {
      display: flex;
      width: max-content;
      gap: 1rem;
      border: none;
      align-items: center;
      border: 1px solid  #E2E8F0;
  
      @include max(1024px) {
        gap: 0.5rem;
      }

      &-number {
        line-height: 1;
        font-weight: 500;
      }
  
      &-btn {
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

  
        .icon {
          transition: 0.3s ease-in-out;
          font-size:1.2rem;
        
          &:hover {
            
              color: $color-pri;

            
          }
        }
      }
    }
  }