.offer {
  &-wrap {
    position: relative;
  }
  &-top {
    padding: 4rem 0;
    background: #fff;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1),
      0px 4px 6px -1px rgba(16, 24, 40, 0.1);
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 3.2rem;
    margin: 0 -1.6rem;
    @include max(992px) {
      margin: 0 -0.8rem;
      row-gap: 1.6rem;
    }

    .offer-item {
      &:nth-child(1),
      &:nth-child(2) {
        width: 100%;

        @include min(501px) {
          .offer {
            &-box {
              padding: 0;
              flex-direction: row;
            }
            &-img {
              width: 50%;
              border-radius: 0;
              padding-top: calc((304 / 624) * 50%);
            }
            &-desc {
              padding: 4rem 6rem;
            }
          }
        }

        @include max(992px) {
          .offer {
            &-desc {
              padding: 2rem;
            }
          }
        }
        @include max(500px) {
          .offer {
            &-desc {
              padding: 0;
            }
          }
        }
      }
    }

    &-blog {
      display: flex;
      flex-wrap: wrap;
      row-gap: 3.2rem;
      margin: 0 -1.6rem;

      @include max(992px) {
        margin: 0 -0.8rem;
        row-gap: 1.6rem;
      }
    }
  }
  &-item {
    width: calc(100% / 3);
    padding: 0 1.6rem;
    @include max(992px) {
      padding: 0 0.8rem;
    }
    @include max(768px) {
      width: 50%;
    }
    @include max(500px) {
      width: 100%;
    }
  }
  &-box {
    transition: 0.3s ease-in-out;
    background: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
    border-radius: 2rem;
    overflow: hidden;
    @include max(768px) {
      padding: 1rem;
    }
    @include hover {
      box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
        0px 10px 15px -3px rgba(16, 24, 40, 0.1);
    }
  }
  &-img {
    display: flex;
    padding-top: calc((192 / 344) * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 1rem 1rem 0 0;

    img {
      @include imgCover;
      transition: 0.4s ease-in-out;
    }
    @include hover {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }
  &-desc {
    display: flex;
    flex-direction: column;
    flex: 1;

    .desc {
      margin-bottom: 1.5rem;
      @include lc(3);
      color: $color-text;
      @include fz-16;
    }

    .btn {
      margin-top: auto;
    }
  }
  &-date {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1.8rem;

    .icon {
      display: flex;
      i {
        @include fz-16;
        color: $color-text;
      }
    }
    .txt {
      color: $color-text;
      line-height: 1;
      margin-top: 0.1rem;
    }
  }
  &-name {
    margin-bottom: 0.4rem;
    transition: 0.3s ease-in-out;
    @include fz-32;
    font-family: "Garamond Premiere Pro", sans-serif;
    font-weight: 600;
    line-height: 1.2;
    color: #111827;
    @include lc(2);

    @include hover {
      color: $color-pri;
    }
  }

  &-de {
    &-box {
      background: #fff;
      border-radius: 3.2rem;
      overflow: hidden;
    }
    &-img {
      display: flex;
      width: 100%;
      padding-top: calc((608 / 1216) * 100%);
      position: relative;

      img {
        @include imgCover;
      }
    }
    &-desc {
      padding: 4rem 7rem;
      color: #000;

      ul {
        padding-left: 2rem;
        list-style: disc;
      }
    }
  }
  &-blog {
    &-btn {
      margin-top: 4rem;
      .btn {
        margin: 0 auto;
      }
    }
  }
  .sre-wrap {
    padding-bottom: 0;
  }
}

.join {
  &-wrap {
    background: #f8f4f0;
  }
  &-inner {
    padding: 4rem 0;
  }
  &-flex {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &-left {
  }
  &-right {
    max-width: 100%;
    .header-srch {
      input {
        background: none;
        height: 6.8rem;
        width: 49rem;
        max-width: 100%;
        border: none;
        border-bottom: 0.1rem solid $color-pri;
        border-radius: 0;
        @include fz-20;
        padding-right: 9rem;

        &::placeholder {
          color: $color-pri;
        }
      }
      .btn {
        width: unset;
        height: 4.4rem;
      }
    }
  }
}

.sre {
    &-wrap {
        position: relative;
    }
  &-ctn {
    margin-top: 4rem;
  }
  &-slide {
    position: relative;

    .offer-item {
      height: 100%;
    }

    .swiper {
      &-slide {
        width: calc(100% / 3);
        height: auto;
        @include max(768px) {
          width: 50%;
        }
        @include max(500px) {
          width: 75%;
        }
        .offer-item {
          width: 100% !important;
          padding: 0;
        }
      }
    }
  }
  &-btn {
    @include max(1330px) {
      display: flex;
      flex-direction: row-reverse;
      gap: 3rem;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

.swiper {
  margin: 0 -1.6rem;
  @include max(992px) {
    margin: 0 -0.8rem;
  }

  &-slide {
    padding: 0 1.6rem;
    @include max(992px) {
      padding: 0 0.8rem;
    }
  }
  &-button {
    &-next,
    &-prev {
      border: 0.1rem solid $color-pri;
      @include mid-flex;
      transition: 0.3s ease-in-out;
      border-radius: 100rem;
      width: 4rem;
      height: 4rem;
      background-color: $color-pri;

      @include max(1330px) {
        position: relative;
        inset: 0 !important;
        margin: 0 !important;
      }

      &:before {
        position: absolute;
        inset: -0.7rem;
        border-radius: 100rem;
        content: "";
        border: 0.1rem dashed $color-pri;
      }

      &::after {
        color: #fff;
        @include fz-16;
        font-weight: 600;
        transition: 0.3s ease-in-out;
      }

      @include hover {
        &::before {
          animation: spin 5s linear infinite;
        }
        &:after {
        }
      }
    }
    &-next {
      right: -9rem;
    }
    &-prev {
      left: -9rem;
    }
    @include max(1400px) {
      &-next {
        right: -5rem;
      }
      &-prev {
        left: -5rem;
      }
    }
  }
  &-pagination {
    position: relative;
    inset: 0 !important;
    margin-top: 3rem;
    &-bullet {
      height: 1rem;
      width: 1rem;
      border-radius: 100rem;
      transition: 0.3s ease-in-out;
      background-color: $color-pri;

      &-active {
        width: 4rem;
        background-color: $color-pri;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
