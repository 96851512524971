.btn {
    @include flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    height: 4.4rem;
    width: fit-content;
    overflow: hidden;
    min-width: 11rem;
    padding: 1rem 2rem;
    @include mid-flex;
    border-radius: 100rem;


    img {
        position: relative;
        z-index: 3;
    }

    .icon {
        width: 2rem;
        height: 2rem;
        position: relative;
        z-index: 3;
        flex-shrink: 0;
        @include mid-flex;

        &:first-child {
            margin-right: 0.8rem;
        }

        &:last-child {
            margin-left: 0.8rem;
        }

        img {
            width: inherit;
            height: inherit;
        }

        i {
            transition: 0.4s;
        }
    }
    &.center {
        margin: auto;
        margin-top: 2rem;
    }
    .text {
        color: $color-white;
        z-index: 3;
        @include fz-16;
        position: relative;
        display: block !important;
        white-space: nowrap;
        font-weight: 600;
        transition: 0.3s ease-in-out;
        line-height: 1;
        text-transform: uppercase;
    }

    &::after {
        content: "";
        position: absolute;
        width: 110%;
        height: 110%;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
        transition: 0.3s ease-in-out;
    }

    &::before {
        content: "";
        position: absolute;
        display: block;
        inset: 0;
        z-index: 2;
        transform: translateX(-101%);
        transform-origin: left center;
        transition: transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    @include min($res-mobile-min) {
        @include hover {
            &::after {
            opacity: 1;
            
            }
        }
    }



    &-pri {
        @extend .btn;
        background: $color-pri;
        border: 1px solid $color-pri;
        transition: 0.3s ease-in-out;

        &::before {
            background:#fff;
        }

        .text {
            color: $color-white;
        }

        .icon {
            i {
                color: $color-white;
            }
        }

        @include hover {
            background:#fff;

            // &::before {
            //     transform: translateX(0);
            // }
            .text {
                color: $color-pri;
            }
            .icon {
                i {
                    color: $color-pri;
                }
            }
        }
    }
 
    &-sec {
        @extend .btn;
        background: #fff;
        border: 1px solid $color-pri;
        transition: 0.3s ease-in-out;

        &::before {
            background:$color-pri;
        }

        .text {
            color: $color-pri;
        }

        .icon {
            i {
                color: $color-pri;
            }
        }

        @include hover {
            background:$color-pri;

            // &::before {
            //     transform: translateX(0);
            // }
            .text {
                color: #fff;
            }
            .icon {
                i {
                    color: #fff;
                }
            }
        }
    }
    &-thir {
        @extend .btn;
        border: 0.1rem solid $color-pri;
        background-color: $color-pri;

        &::before {
            background:#fff;
            content: "";
            position: absolute;
            display: block;
            left: -2px;
            top: 0;
            right: -2px;
            bottom: 0;
            z-index: 2;
            transform: scale(1, 1);
            transform-origin: left center;
            transition: transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transition: transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
   

        .text {
            color: $color-pri;
        }

        .icon {
            i {
                color: $color-pri;
            }
        }

        @include hover {
            &::before {
                transform-origin: right center;
                transform: scale(0, 1);
            }
            .text {
                color: #fff;
            }
            .icon {
                i {
                    color: #fff;
                }
            }
        }
    }
    &-trans {
        @extend .btn;
        border: 0.1rem solid $color-pri;
        transition: 0.3s ease-in-out;

        .text {
            color: $color-pri;
        }

        .icon {
            i {
                color: $color-pri;
            }
        }

        @include hover {
            background: $color-pri;
 
            .text {
                color: #fff;
            }
            .icon {
                i {
                    color: #fff;
                }
            }
        }
    }
    &-custom {
        height: 5.6rem;
        padding: 1rem 1.5rem;
        position: relative;
        .icon {
            flex-shrink: 0;
            width: 4rem;
            height: 4rem;
            background: #fff;
            border-radius: 100rem;
            padding: 0.6rem;
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.5s ease-in-out;
            z-index: 5;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .text {
            text-transform: capitalize;
            padding-left: 5rem;
            transition: 0.4s ease-in-out;

        }
        @include hover {
            .icon {
                left: calc(100% - 5rem);
                right: 1rem;
                background-color: $color-pri;
                img {
                    filter: brightness(0) invert(1);
                    -webkit-animation: shake-lr 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite alternate forwards;
                    animation: shake-lr 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite alternate forwards;
                } 
            }
            .text {
                padding-left:0; 
                padding-right: 5rem;
            }
        }
    }
    &.full {
        width: 100%;
    }

    &.rotate {
        .icon {
            i {
                transform: rotate(-45deg);
            }
        }
        @include hover {
            .icon {
                i {
                    transform: rotate(0);
                }
            }
        }
    }
}


/* ----------------------------------------------
 * Generated by Animista on 2024-3-21 10:12:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-lr
 * ----------------------------------------
 */
 .shake-lr {
	-webkit-animation: shake-lr 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite alternate forwards;
	        animation: shake-lr 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite alternate forwards;
}
 @-webkit-keyframes shake-lr {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    10% {
      -webkit-transform: rotate(8deg);
              transform: rotate(8deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
    }
    80% {
      -webkit-transform: rotate(-8deg);
              transform: rotate(-8deg);
    }
    90% {
      -webkit-transform: rotate(8deg);
              transform: rotate(8deg);
    }
  }
  @keyframes shake-lr {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    10% {
      -webkit-transform: rotate(8deg);
              transform: rotate(8deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
    }
    80% {
      -webkit-transform: rotate(-8deg);
              transform: rotate(-8deg);
    }
    90% {
      -webkit-transform: rotate(8deg);
              transform: rotate(8deg);
    }
  }
  
