.poli {
    position: relative;
    &-wrap {
        position: relative;
    }

}

.bg-paper {
    position: absolute;
    inset: 0;
    z-index: -2;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}