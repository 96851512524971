.recheck {
  &-item {
    @include flex;
    align-items: center;
    cursor: pointer;
    &.active {
      .recheck-checkbox {
        background: $color-pri;
        border-color: $color-pri;
        &::before {
          opacity: 1;
          content: "\f00c";
          color: white;
        }
      }
      .recheck-radio {
        &::before {
          opacity: 1;
        }
      }
      .recheck-dot {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  &-checkbox {
    width: 2rem;
    height: 2rem;
    border-radius: 0.4rem;
    border: 0.1rem solid #D1D5DB;
    transition: 0.3s ease-in-out;
    position: relative;
    flex-shrink: 0;
    &::before {
      content: "";
      @include mid;
      top: calc(50%);
      position: absolute;
      color: $color-pri;
      @include fz-16;
      @include mid-flex;
      font-family: "Font Awesome 5 Pro";
      transition: 0.3s ease-in-out;
      font-weight: 300;
      line-height: 1;
    }
  }
  &-radio {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 100rem;
    border: 0.1rem solid #d9d9d9;
    flex-shrink: 0;
    position: relative;
    &::before {
      content: "";
      @include mid;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 100rem;
      background-color: $color-pri;
      opacity: 0;
    }
  }
  &-method {
    width: 4.8rem;
    height: 4.8rem;
    border: 0.1rem solid #ebebeb;
    border-radius: 0.8rem;
    @include mid-flex;
    margin-left: 0.8rem;
    img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  &-text {
    margin-left: 0.8rem;
    line-height: 1;

    a {
      transition: 0.3s ease-in-out;

      @include hover {
        color: $color-pri;
      }
    }
  }
  &-dot {
    border-radius: 50%;
    width: 1.8rem;
    height: 1.8rem;
    border: 0.1rem solid $color-black;
    position: relative;
    flex-shrink: 0;
    &::before {
      content: "";
      position: absolute;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: inherit;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $color-pri;
      opacity: 0;
      transition: 0.3s ease-in-out;
    }
  }

}
