.ip-control {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &.x2 {
    width: 50%;
  }
  &.x3 {
    width: calc(100% / 3);
  }
  &.x4 {
    width: 25%;
  }

  label {
    @include fz-16;
    font-weight: 400;
    color: #2B2B2B;
    display: block;
    margin-bottom: 0.5rem;
  }
  input {
    width: 100%;
    height: 4.8rem;
    border: 1px solid #d0d5dd;
    background: #fff;
    padding: 0 1.2rem;
    border-radius: 0.8rem;

    &:focus {
      outline: none;
    }
  }
  textarea {
    border: 1px solid #d0d5dd;
    background: #fff;
    padding: 1.6rem;
    min-height: 12rem;
    width: 100% !important;
    @include fz-16;
    border-radius: 0.8rem;

  }
  .dateTimeItem {
    height: 4.8rem;
  }
  .dateTimeText {
    height: 4.8rem;
  }
  &.ip-pass {
      input {
        padding-right: 4rem;
      }
      .ip-feild {
        position: relative;
      }
      .icon {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        width: 2.4rem;
        height: 2.4rem;
        @include mid-flex;
        color:#ADADAD;
        cursor: pointer;
        font-weight: 400;
      }
  }
  &-input {
    position: relative;
    display: flex;
    input {
      flex: 1;
    }
  }
  &-select {
    width: 7rem;

    .select2-selection__rendered,
    .select2-container .select2-selection--single,
    .select2-container {

      border-right: none !important;
    }
  }
  &-date {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
  .select2-container {
    width: 100% !important;
  }
  .select2-container .select2-selection--single {
    height: 4.8rem;
  }
  .select2-container .select2-selection--single,
  .select2-selection__rendered {
    //height: 100%;
    display: flex !important;
    align-items: center;
    background: #fff;
    width: 100%;
    border-radius: 0 !important;
    border-color: #d0d5dd;
  }

  .select2-selection__rendered {
    padding: 0.4rem;
  }

  .select2-selection__arrow {
    height: 100% !important;
  }
  .ip-feild {
    position: relative;
  }
  &.read-only {
    input {
      background: #E2E8F0;
      color: #64748B;
      border-color: #E2E8F0;
    }
  }
}
