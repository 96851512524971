.about {
  overflow: hidden;
  &-top {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
    @include min(1200px) {
      width: calc((10 / 12) * 100%);
      margin: 0 auto;
    }

    .icon {
      width: 5.2rem;
      height: 5.2rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &-ctn {
    margin-top: 3rem;
  }
  &-list {
    display: flex;
    margin: 0 -1.6rem;
    @include max(992px) {
      margin: 0 -0.8rem;
    }
    @include max(550px) {
      margin: 0 -0.4rem;

    }
    @include max(500px) {
      flex-wrap: wrap;
      row-gap: 0.8rem;
    }
  }
  &-item {
    width: calc(100% / 3);
    padding: 0 1.6rem;
    @include max(992px) {
      padding: 0 0.8rem;
    }
    @include max(550px) {
      padding: 0 0.4rem;
    }
    @include max(500px) {
      width: 100%;
      
    }
    @include min(500px) {
      &:nth-child(2) {
        transition-delay: 0.4s !important;
      }
      &:nth-child(3) {
        transition-delay: 0.6s !important;
      }
    }
  }
  &-img {
    padding-top: calc((480 / 384) * 100%);
    overflow: hidden;
    border-radius: 1.2rem;
    position: relative;
    img {
      @include imgCover;
      transition: 0.4s ease-in-out;
    }
    @include hover {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }
  &-bot {
    margin-top: 3rem !important;

    @include min(1200px) {
      width: calc((10 / 12) * 100%);
      margin: 0 auto;
    }
  }
}
.ab {
  overflow: hidden;
  &-wrap {
    position: relative;
  }
  &-flex {
    display: flex;
    @include max(768px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
  &-left {
    width: calc((6.5 / 12) * 100%);
    @include max(768px) {
      width: 100%;
    }
  }
  &-right {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &-box {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  &-img {
    overflow: hidden;
    border-radius: 1.2rem;
    display: flex;

    &:nth-child(1) {
      position: absolute;
      width: calc((3 / 6.5) * 100%);
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-child(2) {
      width: calc((4.5 / 6.5) * 100%);
      padding-top: calc((632 / 481) * 74%);
      position: relative;

      img {
        @include imgCover;
      }
    }
  }
  &-ctn {
    padding: 2rem 7rem;
    padding-right: 0;
    @include max(1200px) {
      padding: 0 2rem;
      padding-right: 0;
    }
    @include max(768px) {
      padding: 0;
    }
  }
}
.smap {
  overflow: hidden;
  &-wrap {
    position: relative;
  }
  &-map {
    position: relative;
  }
  &-bg {
    display: flex;
    width: 63%;
    @include max(992px) {
      width: 100%;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }
  &-ctn {
    width: calc((4 / 12) * 100%);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 58%;

    @include max(992px) {
      position: relative;
      inset: 0;
      transform: none;
      padding: 2rem 1.5rem;
      width: 100%;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      @include max(1200px) {
        gap: 1rem;
      }
    }
  }
  &-map {
    &-ab {
      @include max(768px) {
        position: absolute;
        inset: -20%;
      }
    }
  }
  &-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 6%;
    z-index: 5;

    top: 37%;
    left: 23.5%;

    @include max(992px) {
      top: 44%;
      left: 41.5%;
    }

    .dot {
      background: rgba(207, 139, 44, 1);
      border-radius: 100rem;
      height: 1.2rem;
      width: 1.2rem;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        inset: 0;
        width: 0.5rem;
        height: 0.5rem;
        background: #cf8b2c;
        border-radius: 100rem;
        -webkit-animation: ping 1.2s ease-in-out infinite both;
        animation: ping 1.2s ease-in-out infinite both;
      }

      &::after {
        position: absolute;
        content: "";
        inset: -0.2rem;
        background: #cf8b2c;
        border-radius: 100rem;
        -webkit-animation: ping 1.2s ease-in-out 1s infinite both;
        animation: ping 1.2s ease-in-out infinite 1s both;
      }

      &-inner {
        &:before {
          position: absolute;
          content: "";
          inset: -0.5rem;
          background: #cf8b2c;
          border-radius: 100rem;
          -webkit-animation: ping 1.2s ease-in-out 1s infinite both;
          animation: ping 1.2s ease-in-out 1s infinite both;
        }
      }
    }
  }
  &-txt {
    @include mid-flex;
    gap: 0.5rem;
    position: absolute;
    width: max-content;
    .txt {
      @include fz-20;
      font-weight: 600;
      text-transform: uppercase;
      @include max(1400px) {
        @include fz-18;
      }
    }
    .icon {
      width: 4rem;
      height: 4rem;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &-item {
    position: absolute;

    &.x1 {
      left: 18%;
      top: 42%;
      width: 8%;

      .smap-txt {
        right: 92%;
        bottom: 95%;
      }
      .smap-line {
        .txt {
          left: 27%;
          top: 62%;
        }
      }
    }
    &.x2 {
      left: 23.5%;
      top: 37%;
      width: 3%;

      .smap-txt {
        flex-direction: column;
        bottom: 103%;
        right: -69%;
      }

      .smap-line {
        .txt {
          left: -28%;
          top: 13%;
        }
      }
    }
    &.x3 {
      left: 26.5%;
      top: 38.5%;
      width: 2.5%;

      .smap-txt {
        bottom: 103%;
        left: 27%;
        flex-direction: row-reverse;
      }

      .smap-line {
        .txt {
          left: 65%;
          top: 13%;
        }
      }
    }
    &.x4 {
      left: 26.5%;
      top: 51%;
      width: 11.5%;

      .smap-txt {
        top: 100%;
        left: 51%;
        flex-direction: column-reverse;
        @include min(1800px) {
          left: 58%;
        }
    
      }

      .smap-line {
        .txt {
          left: 53%;
          top: 51%;
        }
      }
    }

    @include max(992px) {
      .smap-line .txt {
        display: none;
      }
      .smap-txt .icon {
        width: 3rem;
        height: 3rem;
      }
      &.x1 {
        left: 36%;
        top: 47.5%;
      }
      &.x2 {
        left: 41.5%;
        top: 44%;
        .smap-txt {
          bottom: 119%;
          right: -122%;
        }
      }
      &.x3 {
        left: 44.5%;
        top: 45.5%;
      }
      &.x4 {
        left: 44.5%;
        top: 53%;
      }
    }
    @include max(500px) {
      .smap-txt .icon {
        width: 2rem;
        height: 2rem;
      }
      &.x4 .smap-txt {
        left: 26%;
      }
      &.x2 .smap-txt {
        right: -175%;
      }
    }
  }
  &-line {
    display: flex;

    img {
      width: 100%;
    }

    .txt {
      color: $color-pri;
      font-weight: 600;
      position: absolute;
      background: #f9fafb;
      width: max-content;
      @include max(1400px) {
        font-size: 1.2rem;
      }
    }
  }
}
.smore {
  &-wrap {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      height: 6rem;
      width: 0.1rem;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      background: $color-pri;
      @include max(992px) {
        height: 4rem;
      }
    }
  }
  &-inner {
    @include min(1200px) {
      width: calc((10 / 12)* 100%);
      margin: 0 auto;
    }
  }
  &-flex {
    display: flex;
    margin: 0 -1.6rem;
    @include max(992px) {
      margin: 0 -0.8rem;
    }
    @include max(768px) {
      flex-direction: column;
      row-gap: 2rem;
    }
  }
  &-left {
    width: calc((4 / 10) * 100%);
    padding: 0 1.6rem;
    @include max(992px) {
      padding: 0 0.8rem;
    }
    @include max(768px) {
      width: 100%;
    }
  }
  &-right {
    padding: 0 1.6rem;
    flex: 1;
    @include max(992px) {
      padding: 0 0.8rem;
    }

    &-inner {
      overflow: hidden;
    }
  }

  &-ctn {
    display: flex;
    gap: 3rem;
    flex-direction: column;
    margin-bottom: 3rem;
    @include max(992px) {
      row-gap: 2rem;
      margin-bottom: 2rem;
    }
  }
  &-img {
    padding-top: calc((714 / 519) * 100%);
    position: relative;
    overflow: hidden;
    display: flex;
    border-radius: 1.2rem;
    height: 100%;

    @include max(768px) {
      padding-top: calc((392 / 665) * 100%);

    }

    img {
      @include imgCover;
      transition: 0.4s ease-in-out;
    }
    @include hover {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }
  &-subimg {
    padding-top: calc((392 / 665) * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 1.2rem;
    display: flex;

    img {
      @include imgCover;
      transition: 0.4s ease-in-out;
    }
    @include hover {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }
  &-slide {
    position: relative;

    .swiper {
      &-slide {
        &-active {
          .smore-img {
            // -webkit-animation: slide-in-bottom 0.8s
            //   cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            // animation: slide-in-bottom 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            //   both;

              // @include max(768px) {
              //   -webkit-animation: slide-in-left 0.8s
              //   cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
              // animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
              //   both;
              // }
          }
          .smore-ctn {
            // -webkit-animation: slide-in-right 0.8s
            //   cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            // animation: slide-in-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            //   both;
          }
          .smore-subimg {
            // -webkit-animation: slide-in-left 0.8s
            //   cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            // animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            //   both;
          }
        }
      }
    }
  }
  &-btn {
    @include max(1330px) {
      display: flex;
      flex-direction: row-reverse;
      gap: 3rem;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

.a-info {
  .sinfo-desc {
    @include hover {
      .desc {
        -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-3-21 16:19:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-3-21 16:20:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-3-21 16:23:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-3-21 10:27:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation ping
 * ----------------------------------------
 */
.ping {
  -webkit-animation: ping 0.8s ease-in-out infinite both;
  animation: ping 0.8s ease-in-out infinite both;
}
@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
}


.sval {
  &-wrap {
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
  }
  &-ctn {
    min-height: calc((619/1728) * 100vw);
    color: #FEFEFE;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    gap: 2rem;
  }
  &-top {
    @include max(768px) {
      br {
        display:none;
      }
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
  }
  &-item {
    width: 25%;
    display: flex;
    flex-direction:column;
    align-items: center;
    @include max(768px) {
      width: 50%;
    }
    @include max(400px) {
      width: 100%
    }

    .num {
      @include fz-128;
      font-weight: 600;
      line-height: 1.2;
    }
    .txt {
      @include fz-24;
    }
  }
}

.awar {
  overflow: hidden;
  &-wrap {
    padding: 8rem 0;
    @include max(992px) {
      padding: 4rem 0;
    }
  }
  &-ctn {
    margin-top: 3rem;
  }
  &-list {
    display: flex;
    margin: 0 -1.6rem;
    flex-wrap: wrap;
    row-gap: 3rem;
    justify-content:center;
    @include max(992px) {
      margin: 0 -0.8rem;
    }
    @include max(550px) {
    }
  }
  &-item {
    width: calc(100% / 3);
    padding: 0 1.6rem;
    @include max(992px) {
      padding: 0 0.8rem;
    }
    @include max(700px) {
      width: 50%;
    }
    @include max(550px) {
      width: 100%;
    }
    @include min(700px) {
      &:nth-child(2) {
        transition-delay: 0.4s !important;
      }
      &:nth-child(3) {
        transition-delay: 0.6s !important;
      }
    }
  }
  &-box {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    @include max(992px) {
      gap: 1.5rem;
    }
  }
  &-img {
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 1.2rem;

    img {
      @include imgCover;
      transition: 0.4s ease-in-out;
    }
    @include hover {
      img {
        transform: translate(-50%,-50%) scale(1.05);
      }
    }
  }
  &-desc {
    display: flex;
    flex-direction: column;
    align-items: center;

    .txt {
      @include lc(3);
      text-align: center;
    }
  }
}

